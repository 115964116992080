import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { AuthService } from '../../_services/authentication-management/auth.service';
import { TokenStorageService } from '../../_services/authentication-management/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(private authService: AuthService
    , private tokenStorage: TokenStorageService
    , private alertService: AlertService
    , private router: Router) { }

  ngOnInit(): void {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    // remove any messages. Auth issues and other login redirects should not be showing messages here
    this.alertService.clear();
  }

  onSubmit(): void {
    const { username, password } = this.form;

    this.authService.login(username, password).subscribe(
      data => {
        console.log('data roles after login:', data.data.roles);
        this.tokenStorage.saveToken(data.data.jwToken);
        this.tokenStorage.saveUser(data.data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().roles;
        
        this.router.navigate(['/orders-outbound']);
        //this.reloadPage();
      },
      err => {
        this.errorMessage = err.error?.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }
}