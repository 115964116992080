import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boards-demo',
  templateUrl: './boards-demo.component.html',
  styleUrls: ['./boards-demo.component.css']
})
export class BoardsDemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
