<div class="table sticky-header">
    <div class="row">
        <div class="col">
            <h1>Saved Recipients</h1>
            <button mat-raised-button color="primary" (click)="goToAddRecipient()">
                Add Recipient
            </button>
        </div>
    </div>
</div>
  <div class="table">
    <div class="row filter-by-wrapper">
      <div class="col">
        <mat-form-field appearance="fill">
          <mat-label>Filter by keywords</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Enter 3 characters or more" 
              #input 
              autocomplete="off"
              class="filterInput">
        </mat-form-field>
      </div>      
    </div>
  </div>
  



  
<table mat-table [dataSource]="dataSource" 
    class="mat-elevation-z8 table-wrapper"
    matSort matSortActive="displayName" matSortDirection="asc" matSortDisableClear>
    <!-- Id Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> 
            #{{element.id}}
        </td>
    </ng-container>

    <!-- display name Column -->
    <ng-container matColumnDef="displayName">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Display Name </th>
        <td mat-cell *matCellDef="let element"> 
            <a  [routerLink]="[element.id]">{{element.displayName}} </a>
        </td>
    </ng-container>
    
    <!-- IsActive Column -->
    <ng-container matColumnDef="isActive">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let element"> 
            <i *ngIf="element.isActive" class="fa fa-check"></i>
            <i *ngIf="!element.isActive" class="fa fa-times"></i>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator #paginator [pageSize]="25"
        class="pager-container"
        showFirstLastButtons="true"
         [pageSizeOptions]="[10, 25, 100, 250, 500]">
</mat-paginator>