<form [formGroup]="userGroup" (ngSubmit)="onUserGroupSubmit()">
    <h1>Change Password</h1>

    <div class="form-row">
        <div class="form-group col">
            <label>Current Password</label>
            <input type="text" formControlName="currPassword" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.currPassword.errors }" />
            <div *ngIf="userSubmitted && ug.currPassword.errors" class="invalid-feedback">
                <div *ngIf="ug.currPassword.errors.required">Current Password is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>New Password <b>(Requires at least 1 number, 1 capital letter AND at least 1 special character)</b></label>
            <input type="text" formControlName="newPassword1" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.newPassword1.errors }" />
            <div *ngIf="userSubmitted && ug.newPassword1.errors" class="invalid-feedback">
                <div *ngIf="ug.newPassword1.errors.required">New password is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Retype New Password</label>
            <input type="text" formControlName="newPassword2" class="form-control" />
        </div>
    </div>
    
    <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
    </button>
    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
</form>