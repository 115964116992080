// FROM https://www.tektutorialshub.com/angular/angular-http-error-handling/#catch-error-globally-using-http-interceptor

import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { Router } from "@angular/router";
import { TokenStorageService } from "../_services/authentication-management/token-storage.service";
 
@Injectable()
export class GlobalHttpAuthErrorInterceptorService implements HttpInterceptor {
    
  constructor(public router: Router
    , private tokenStorageService: TokenStorageService) {
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        //console.log('error is intercept')
        console.error(error);
        
        /*
          WHEN the JWT Token expires, the server starts to error...
          All users should be redirected to login page with a message of what happened.


          EXAMPLE ERROR:
          Unexpected error getting OutboundOrders. message:

          {"headers":{"normalizedNames":{},"lazyUpdate":null},"status":500,"statusText":"OK","url":"https://localhost:44377/api/v1/OrdersOutbound/?pageNumber=1&pageSize=25","ok":false,"name":"HttpErrorResponse","message":"Http failure response for https://localhost:44377/api/v1/OrdersOutbound/?pageNumber=1&pageSize=25: 500 OK","error":"Microsoft.IdentityModel.Tokens.SecurityTokenExpiredException: IDX10223: Lifetime validation failed. The token is expired. ValidTo: 'System.DateTime', Current time: 'System.DateTime'.\r\n at Microsoft.IdentityModel.Tokens.Validators.ValidateLifetime(Nullable`1 notBefore, Nullable`1 expires, SecurityToken securityToken, TokenValidationParameters validationParameters)\r\n at System.IdentityModel.Tokens.Jwt.JwtSecurityTokenHandler.ValidateLifetime(Nullable`1 notBefore, Nullable`1 expires, JwtSecurityToken jwtToken, TokenValidationParameters validationParameters)\r\n at System.IdentityModel.Tokens.Jwt.JwtSecurityTokenHandler.ValidateTokenPayload(JwtSecurityToken jwtToken, TokenValidationParameters validationParameters)\r\n at System.IdentityModel.Tokens.Jwt.JwtSecurityTokenHandler.ValidateToken(String token, TokenValidationParameters validationParameters, SecurityToken& validatedToken)\r\n at Microsoft.AspNetCore.Authentication.JwtBearer.JwtBearerHandler.HandleAuthenticateAsync()"}
        */

        // don't let unauthorized or expired users in :)
        if(error.status == 401 
          || error.error?.indexOf('Lifetime validation failed. The token is expired.') > -1){          
          this.tokenStorageService.signOut();
          this.router.navigateByUrl('/login');
        }
        
        // (always?) pipe the error up to the originating .subscribe(...)
        return throwError(error.message);
      })
    )
  }
}
