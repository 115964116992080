
<h1 class="order-header">View Order Details: <i>{{order.orderNumber}}</i></h1>
<div class="order-status-wrapper">
    <div class="row">
        <div class="col-md-6">
            <span>Order Status</span>
            <span class="orderStatusValue">{{order.orderStatus}}</span>
        </div>
    </div>
</div>

<div class="table">
    <div class="row">
        <div class="col-md-7">
            <div class="orderDetailsWrapper table">
                <div class="row">
                    <div class="col-md-4">
                        <label>Order Number</label>
                    </div>
                    <div class="col-md-8">
                        {{order.orderNumber}}
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                        <label>Order Status</label>
                    </div>
                    <div class="col-md-8">
                        {{order.orderStatus}}
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                        <label>Created</label>
                    </div>
                    <div class="col-md-8">
                        {{order.createDate | date: 'mediumDate'}}
                    </div>
                </div> 
                <div class="row top-padding">
                    <div class="col">
                        <h2>Shipped To</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Name</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.name}}
                    </div>
                </div> 
                <div class="row" *ngIf="order.shipToAddress?.company?.trim() !== ''">
                    <div class="col-md-4">
                        <label>Company</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.company}}
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                        <label>Address Line</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.street1}}
                    </div>
                </div> 
                <div class="row" *ngIf="order.shipToAddress?.street2?.trim() !== ''">
                    <div class="col-md-4">
                        <label>Address Line 2</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.street2}}
                    </div>
                </div> 
                <div class="row" *ngIf="order.shipToAddress?.street3?.trim() !== ''">
                    <div class="col-md-4">
                        <label>Address Line 3</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.street3}}
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                        <label>City</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.city}}
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                        <label>State</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.state}}
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-4">
                        <label>Postal Code</label>
                    </div>
                    <div class="col-md-8">
                        {{order.shipToAddress?.postalCode}}
                    </div>
                </div> 
 





                <div class="row top-padding">
                    <div class="col">
                        <h2>Items On Order</h2>
                    </div>
                </div>
                <div class="row" *ngFor="let item of order.lineItems; let i = index;">
                    <div class="col-md-1">
                        &nbsp;
                    </div>
                    <div class="col-md-3">
                        <label>{{item.name}}</label>
                        
                        <br/>
                        <span class="smaller-text">
                            <label>SKU:&nbsp;</label>
                            {{item.sku}}
                        </span>
                    </div>
                    <div class="col-md-4">
                        <label>Quantity</label>
                        
                        <br/>
                        <span class="bigger-text">
                            {{item.quantity}}
                        </span>
                    </div>
                </div> 
            </div>
        </div>







        <div class="col-md-5">
            <div class="table shipmentDetailsWrapper"
                *ngIf="order.orderStatus.toLowerCase() === 'shipped'">
                <div class="row">
                    <div class="col">
                        <h2>Shipments</h2>
                    </div>
                </div>
                <div class="row"  *ngFor="let shipment of order.shipments">
                    <div class="col-md-4">
                        <label>Tracking #</label>
                        <br/>
                        {{shipment.trackingNumber}}
                    </div>
                    <div class="col-md-8">
                        <label>Shipped with</label>
                        {{shipment.carrierCode}}
                        &nbsp; - &nbsp; 
                        {{shipment.serviceCode}}
                    </div>
                </div> 
            </div>  
        </div>
    </div>
</div>