import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/authentication-management/auth.service';


// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
// import { MenuModule } from '@ag-grid-enterprise/menu';
// import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
// import '@ag-grid-community/core/dist/styles/ag-grid.css';
//import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  /*
  public modules: Module[] = [
    ClientSideRowModelModule,
    RowGroupingModule,
    MenuModule,
    ColumnsToolPanelModule,
  ];*/
  columnDefs;
  defaultColDef;
  autoGroupColumnDef;
  sideBar;
  rowData: any[];


  
  

  
  constructor(private authService: AuthService) { 
    this.seedData();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      resizable: true,
    };
    this.autoGroupColumnDef = { minWidth: 250 };
    this.sideBar = 'columns';
  }

  ngOnInit(): void {
    
    this.authService.testAuth().subscribe(
      data => {
        console.log('brand test data', data);
      },
      err => {
        console.log('ERROR getting brand:', err.error?.message);
      }
    );
  }

  
  private seedData() {
    
    this.columnDefs = [
      { field: 'make', sortable: true,
      pivot: true, filter: true },
      { field: 'model', sortable: true,
      pivot: true, filter: true },
      { field: 'price', sortable: true, filter: true }
  ];
  
  this.rowData = [
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Porsche", "model": "Boxter", "price": 72000 },
    { "make": "Toyota", "model": "Celica", "price": 35000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 },
    { "make": "Ford", "model": "Mondeo", "price": 32000 }
  ];
    }

}
