<h1 class="header" *ngIf="isCreateMode">Create User: <i>{{ug.fullName.value}}</i></h1>
<h1 class="header" *ngIf="!isCreateMode">Edit User: <i>{{ug.fullName.value}}</i></h1>

<form [formGroup]="userGroup" (ngSubmit)="onUserGroupSubmit()">
    <h2>Basic Info</h2>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label>First Name</label>
            <input type="text" placeholder="First Name" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.firstName.errors }" />
            <div *ngIf="userSubmitted && ug.firstName.errors" class="invalid-feedback">
                <div *ngIf="ug.firstName.errors.required">First name is required</div>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.lastName.errors }" />
            <div *ngIf="userSubmitted && ug.lastName.errors" class="invalid-feedback">
                <div *ngIf="ug.lastName.errors.required">Last name is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Username</label>
            <input type="text" placeholder="Username" formControlName="userName" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.userName.errors }" />
            <div *ngIf="userSubmitted && ug.userName.errors" class="invalid-feedback">
                <div *ngIf="ug.userName.errors.required">Username is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Email</label>
            <input type="text" placeholder="your_email@website.com" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.email.errors }" />
            <div *ngIf="userSubmitted && ug.email.errors" class="invalid-feedback">
                <div *ngIf="ug.email.errors.required">Email is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Phone Number</label>
            <input type="text" placeholder="888-123-1234" formControlName="phoneNumber" class="form-control" />
        </div>
    </div>
    
    <div class="form-row">
        <div class="form-group col">
            <label>Is Active
                <input type="checkbox" formControlName="isActive" class="form-control" [readonly]="isCreateMode" />
            </label>
        </div>
    </div>

    <!-- ROLES -->
    
    <div class="form-row">
        <div class="form-group col-md-6">
            <div class="table">
                <div class="row">
                    <div class="col">
                        <h2>Roles</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <ul>
                            <li *ngFor="let role of allRoles">
                                <label>
                                    <input type="checkbox" (click)="roleChecked(role)" [checked]="rolesForThisUser.indexOf(role) > -1" />
                                    {{role}}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


    <!-- ASSOCIATED 3PL CLIENT -->
        <div class="form-group col-md-6">
            <div class="table">
                <div class="row">
                    <div class="col">
                        <h2>Associated 3PL</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width product-list">
                            <ng-select ngSelectMat [items]="all3plClients"
                                bindLabel="simpleWms3plConfigId"
                                placeholder="Selected Client"
                                formControlName="associated3plConfigId"    
                                [searchFn]="clientSearch"
                                required="true" >

                                <!-- VIEW template -->
                                <ng-template ng-label-tmp let-item="item" let-index="index">
                                    <ng-container>
                                        {{item.name}}
                                    </ng-container>
                                </ng-template>

                                <!-- EDIT template -->
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <ng-container>
                                        {{item.name}}
                                    </ng-container>
                                </ng-template>
                            </ng-select>

                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
         
    
    <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Submit
    </button>
    
    <a (click)="goToChangePassword()" class="btn btn-secondary password-btn">
        Change Password
    </a>
    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
</form> 