import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { throwIfEmpty } from 'rxjs/operators';
import { FileUploadService } from './file-upload.service';
import { WeFile } from './models/we-file';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  public progress: number;
  public message: string;
  
  @Output() public onUploadFinished = new EventEmitter();
  @Input() public readonly: boolean = false;
  @Input() public files: WeFile[];
  @Input() public fileType: string;
  @Input() public attachMultipleFiles: boolean;

  constructor(private http: HttpClient
    , private fileService: FileUploadService)
    { }

  ngOnInit(): void {
  }

  onDeleteFile(file, fileIndex){
    //console.log('delete');
    // delete from DB
    this.fileService.deleteFile(file.blobId)    
      .subscribe((data) => {
        console.log('delete file resp:', data, 'index:', fileIndex);
        // emit to clients (??? or will 2way binding sync things under the hood ????)
      });

      
        // delete from our UI rows
        this.files.splice(fileIndex, 1);
  }

  
  downloadFile(blobId, filename){
    //console.log('download');
    this.fileService.downloadFile(blobId)
    .subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = filename;
      link.click();
    
    });
  }

  

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    console.log('uploading file from outbound order component');

    let filesToUpload : File[] = files;
    const formData = new FormData();
      
    // handle 1+ docs uploaded at once
    Array.from(filesToUpload).map((file, index) => {
      return formData.append('file'+index, file, file.name);
    });

    this.fileService.uploadFiles(this.fileType, formData)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          var newFiles = event.body as WeFile[];

          // new files vs upload additional
          if(this.files == undefined){
            this.files = newFiles;
          }else{
            newFiles.forEach(file => {
              this.files.push(file);
            });
          }
          this.onUploadFinished.emit(this.files);
        }
      });
  }

}
