import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap  } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { OutboundOrdersDataSource } from './dataSource/outbound-orders.data-source';
import { OrdersOutbound } from './models/ordersOutbound';
import { OutboundOrdersService } from './orders-outbound.service';

@Component({
  selector: 'app-orders-outbound',
  templateUrl: './orders-outbound.component.html',
  styleUrls: ['./orders-outbound.component.css']
})
export class OrdersOutboundComponent implements OnInit, AfterViewInit {

  OutboundOrdersOnThisPage: OrdersOutbound[];
  displayedColumns: string[] = ['id', 'orderStatus', 'name', 'orderSource', 'dateRequested', 'itemsOrdered', 'totalUnits'];
  dataSource: OutboundOrdersDataSource;
  // mostly setup table from tutorial: https://blog.angular-university.io/angular-material-data-table/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

 

  // vars to track filters applied to the page
  filterKeyword: string = '';
  filterOrderSource: string = 'ANY';
  filterStatuses: string[] = ['CREATED','REQUESTED','PICKING','PICKED','CHECKING','ISSUED'];
  @ViewChild(MatDateRangePicker) dateRequestedPicker: MatDateRangePicker<any>;
  @ViewChild(MatStartDate) dateRequestedStart: MatStartDate<any>;
  @ViewChild(MatEndDate) dateRequestedEnd: MatEndDate<any>;
  @ViewChild(MatInput) input: MatInput;


  constructor(private OutboundOrdersService: OutboundOrdersService,
    private alertService: AlertService,
    private router: Router) { }


  ngOnInit(): void { }

  ngAfterViewInit() {
    // fill orders table with default pager settings on load
    this.getOutboundOrders();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.resetPagingToFirstPage());
    
    // when sort order or page details change, call server to get next dataset
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.getOutboundOrders(this.paginator.pageIndex, this.paginator.pageSize))
        )
        .subscribe();
}

  resetPagingToFirstPage(){
    // reset the paginator after sorting
    this.paginator.pageIndex = 0
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterKeyword = filterValue?.trim()?.toLowerCase();

    // when more than 3 chars, get new data from server
    if(this.filterKeyword?.length >= 3){
      this.resetPagingToFirstPage();
      this.getOutboundOrders(this.paginator.pageIndex, this.paginator.pageSize)
    }
  }

  goToAddOutboundOrder(){
    // go to edit page without an ID to trigger Add
    this.router.navigate(['/orders-outbound', '']);
  }

  changeFilterStatus($event){
    this.resetPagingToFirstPage();
    this.getOutboundOrders(this.paginator.pageIndex, this.paginator.pageSize);
  }

  changeFilterSource($event){
    this.resetPagingToFirstPage();
    this.getOutboundOrders(this.paginator.pageIndex, this.paginator.pageSize);
  }

  changeFilterDate($event){
    this.resetPagingToFirstPage();
    this.getOutboundOrders(this.paginator.pageIndex, this.paginator.pageSize);
  }

  private getOutboundOrders(pageNumber: number = 0, pageSize: number = 25){
    // UI count starts at 0, but backend starts at 1
    pageNumber = pageNumber + 1;
    
    this.dataSource = new OutboundOrdersDataSource(this.OutboundOrdersService, this.alertService);
    this.dataSource.setPaginator(this.paginator);
    
    let startDt:any;
    let endDt: any;
    if(this.dateRequestedStart.value != null && this.dateRequestedStart.value != undefined){
      startDt = this.dateParse(this.dateRequestedStart.value as string).toISOString();
    }else{
      startDt = '';
    }
    if(this.dateRequestedEnd.value != null && this.dateRequestedEnd.value != undefined){
      endDt = this.dateParse(this.dateRequestedEnd.value as string).toISOString();
    }else{
      endDt = '';
    }

    var filterStatusToSendToServer = this.filterStatuses;
    // all options are selected, explicitly tell server "ANY" status is desired
    if(this.filterStatuses.length >= 6){
      filterStatusToSendToServer.push("ANY");
    }

    this.dataSource.loadOrders(this.filterKeyword, this.sort.active, this.sort.direction, pageNumber, pageSize, filterStatusToSendToServer, startDt, endDt, this.filterOrderSource);
  }

  private dateParse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
