import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AlertService } from "src/app/lib/alerts/alert.service";
import { SavedRecipientsService } from "../saved-recipients/saved-recipients.service";

export class SavedRecipientsDataSource implements DataSource<any> {

    private subject = new BehaviorSubject<any[]>([]);
    private totalResults: number = 0;

    
    protected paginator: MatPaginator;

    constructor(private savedRecipientsService: SavedRecipientsService,
        private alertService: AlertService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
    }

    loadRecipients(
        filterSearchText = ''
        , sortField = 'DISPLAYNAME', sortOrder = 'ASC'
        ,pageNumber = 1, pageSize = 25) {
        this.savedRecipientsService.findRecipients(filterSearchText, sortField, sortOrder,
            pageNumber, pageSize)
            .pipe(
                catchError(() => {
                    this.alertService.error('Error loading saved recipients.')
                    return of(null)
                })
        )
        .subscribe(orders => {
            this.totalResults = orders["totalCount"];
             this.subject.next(orders["data"]);
             this.paginator.length = this.totalResults;
        });
    }    


    setPaginator( pgntr: MatPaginator ) : void {
        this.paginator = pgntr;
    }

    getTotalRecords(){
        return this.totalResults;
    }
}