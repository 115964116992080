import { Component, Input, OnInit } from '@angular/core';
import { CardStore } from '../models/card-store';
import { ListSchema } from '../models/list-schema';

@Component({
  selector: 'app-we-list',
  templateUrl: './we-list.component.html',
  styleUrls: ['./we-list.component.css']
})
export class WeListComponent implements OnInit {
  @Input() list: ListSchema;
  @Input() cardStore: CardStore;
  displayAddCard = false;
  
  constructor() {

  }

  toggleDisplayAddCard() {
    this.displayAddCard = !this.displayAddCard;
  }

  ngOnInit(): void {}

  allowDrop($event) {
    $event.preventDefault();
  }

  drop($event) {
    $event.preventDefault();
    const data = $event.dataTransfer.getData("text");
    let target = $event.target;
    const targetClassName = target.className;
    while (target.className !== "list") {
      target = target.parentNode;
    }

    target = target.querySelector(".cards");
    if (targetClassName === "card") {
      $event.target.parentNode.insertBefore(
        document.getElementById(data),
        $event.target
      );
    } else if (targetClassName === "list__title") {
      if (target.children.length) {
        target.insertBefore(document.getElementById(data), target.children[0]);
      } else {
        target.appendChild(document.getElementById(data));
      }
    } else {
      target.appendChild(document.getElementById(data));
    }
  }

  onEnter(value: string) {
    const cardId = this.cardStore.newCard(value);
    this.list.cards.push(cardId);
  }

}
