<p class="larger-text">
    NOTE: Product imports must use 
    <a href="../../../assets/templates/product_import_template_simple_wms_v0_1.xlsx" target="_blank">
        this Excel Template
    </a>.
</p>
<p class="larger-text">
    <b>All fields are required</b>
</p>

<input type="file" (change)="onFileChange($event)" multiple="false" />

<table mat-table [dataSource]="data" class="mat-elevation-z8 table-wrapper" #importTableName>
    <!-- Name Column -->
    <ng-container matColumnDef="{{col}}" *ngFor="let col of displayedColumns; index as i" >
      <th mat-header-cell *matHeaderCellDef> {{col}} </th>
      <td mat-cell *matCellDef="let element"> 
          {{element[i]}}
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


<br/><br/>
<div *ngIf="data !== null && data.length > 0">
    <p>
        Confirm all data above looks accurate and ready for import into Simple WMS.
    </p>
    <button (click)="import()" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Import Products
    </button>
    <!-- dont need to send back to excel currently-->
    <!-- <button (click)="export()" class="btn btn-primary">Export!</button> -->
</div>