import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { AssociatedClient3plsService } from './associated-client3pls.service';
import { Associated3pl } from './models/associated-3pl';

@Component({
  selector: 'app-associated-client3pls',
  templateUrl: './associated-client3pls.component.html',
  styleUrls: ['./associated-client3pls.component.css']
})
export class AssociatedClient3plsComponent implements OnInit {
  client3plsOnThisPage: Associated3pl[];
  displayedColumns: string[] = ['simpleWms3plConfigId', 'name', 'canAccessPortal'];
  dataSource:any;

  constructor(private client3plService: AssociatedClient3plsService,
    private userRoleService: UserRoleService,
    private router: Router,
    private alertService: AlertService) 
  { }

  ngOnInit(): void {    
    this.userRoleService.RedirectIfNotAdminUser();

    this.client3plService.getAllClient3pls()
        .subscribe(
          result =>
          {
            this.client3plsOnThisPage = result.data.clients;
            this.dataSource = new MatTableDataSource(this.client3plsOnThisPage);
          },
          error =>
          {
            this.alertService.error(
              'Unexpected error getting users. message:<br/><br/>' 
            + JSON.stringify(error));
          }
        )
  }

  goToAddClient(){
    // go to edit page without an ID to trigger Add
    this.router.navigate(['/clients', '']);
  }

}
