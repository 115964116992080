<h1>Users</h1>

<button mat-raised-button color="primary" (click)="goToAddUser()">
  Add User
</button>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-wrapper">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> 
          <a [routerLink]="[element.id]">{{element.id}} </a>
        </td>
    </ng-container>
  
    <!-- FullName Column -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> 
        <a [routerLink]="[element.id]"> {{element.fullName}} </a>
      </td>
    </ng-container>
  
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
  
    <!-- Phone Number Column -->
    <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>
  
    <!-- 3PL Client Column -->
    <ng-container matColumnDef="associated3plClient">
        <th mat-header-cell *matHeaderCellDef> 3PL Client </th>
        <td mat-cell *matCellDef="let element"> {{element.associated3plClient}} </td>
      </ng-container>

      
    <!-- Is Active Column -->
    <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let element"> {{element.isActive}} </td>
      </ng-container>
      
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>