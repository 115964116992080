<h1 *ngIf="isAddMode">Add Product</h1>
<h1 *ngIf="!isAddMode">Edit Product</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label>Product Name</label>
            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label>Description</label>
            <textarea type="text" formControlName="description" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
            </textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">Description is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-7">
            <label>Barcode</label>
            <input type="text" formControlName="barcode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.barcode.errors }" />
            <div *ngIf="submitted && f.barcode.errors" class="invalid-feedback">
                <div *ngIf="f.barcode.errors.required">Barcode is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label>Price</label>
            <input formControlName="rate" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && f.rate.errors }" />
            <div *ngIf="submitted && f.rate.errors" class="invalid-feedback">
                <div *ngIf="f.rate.errors.pattern">Rate must be a positive decimal value with, at most, 2 decimal places.</div>                
                <div *ngIf="f.rate.errors.required">Rate is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
            </button>
            <a (click)="cancel()"  class="btn btn-link">Cancel</a>
        </div>
    </div>
</form>

<div  *ngIf="!isAddMode">
    <h2>Manage Product Outbound Channels</h2>

    <mat-accordion [multi]="true">
        <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
            <b>Shopify Product Details</b>
            </mat-panel-title>
            <mat-panel-description>
            View all details of this product in Shopify.
            </mat-panel-description>
        </mat-expansion-panel-header>
        <table style="border: 1px solid black;">
            <tr>
                <td>
                    Title
                </td>
                <td>
                    My Shopify Product Title
                </td>
            </tr>
            
            <tr>
                <td>
                    Description
                </td>
                <td>
                    my Product's awesome Shopify HTML description.
                </td>
            </tr>

                <tr>
                <td>
                    Pricing
                </td>
                <td>
                    All pricing from Shopify will display here. i.e.
                    <br/><br/>
                    Sale Price: $9.95
                    Price: $12.95
                    Cost per item: $4.97
                </td>
            </tr>
        </table>
        </mat-expansion-panel>

        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
            <mat-panel-title>
                <b>Amazon Product Details</b>
            </mat-panel-title>
            <mat-panel-description>
                View all details of this product on the Amazon listing.
            </mat-panel-description>
            </mat-expansion-panel-header>
            
        <table>
            <tr>
                <td>
                    Title
                </td>
                <td>
                    My Amazon Product Title
                </td>
            </tr>
            
            <tr>
                <td>
                    Description
                </td>
                <td>
                    my Product's awesome Amazon HTML description.
                </td>
            </tr>

                <tr>
                <td>
                    Pricing
                </td>
                <td>
                    All pricing from Shopify will display here. i.e.
                    <br/><br/>
                    Sale Price: $9.95
                    Price: $12.95
                    Cost per item: $4.97
                </td>
            </tr>
        </table>
        </mat-expansion-panel>
    </mat-accordion>
</div>