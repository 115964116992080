import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AlertService } from "src/app/lib/alerts/alert.service";
import { WarehouseOutboundsService } from "../warehouse-outbounds.service";

export class WarehouseOutboundOrdersDataSource implements DataSource<any> {

    private ordersSubject = new BehaviorSubject<any[]>([]);
    private totalResults: number = 0;

    
    protected paginator: MatPaginator;

    constructor(private outboundOrderService: WarehouseOutboundsService,
        private alertService: AlertService) {}

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.ordersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.ordersSubject.complete();
    }

    loadOrders(
        orderStatusGroup = 'TO_SHIP'
        ,pageNumber = 1, pageSize = 250) {
        this.outboundOrderService.getOrdersByStatusGroup(orderStatusGroup,
            pageNumber, pageSize).pipe(
                catchError(() => {
                    this.alertService.error('Error loading orders.')
                    return of(null)
                })
        )
        .subscribe(orders => {
            this.totalResults = orders["totalCount"];
             this.ordersSubject.next(orders["data"]);
             this.paginator.length = this.totalResults;
        });
    }    


    setPaginator( pgntr: MatPaginator ) : void {
        this.paginator = pgntr;
    }

    getTotalRecords(){
        return this.totalResults;
    }
}