import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private alertService: AlertService) { }

  
  public RedirectIfNotAdminUser() : void{
    var isAdmin = false;
    
    var isLoggedIn = this.tokenStorageService.isLoggedIn();
    if(isLoggedIn){
      var user = this.tokenStorageService.getUser();

      isAdmin = user.roles.includes('Admin');
    }

    if(!isAdmin){
      this.redirectToHome();
    }
  }

  
  // TODO: need to improve this to be way reusable in the future...
  public RedirectIfNotCurrentUserAccess(idToEdit: any) : void{
    var userHasAccess = false;
    var isAdmin = false;
    
    var isLoggedIn = this.tokenStorageService.isLoggedIn();
    if(isLoggedIn){
      var user = this.tokenStorageService.getUser();

      isAdmin = user.roles.includes('Admin');

      if(idToEdit == user.id){
        userHasAccess = true;
      }
      // admins get access to everything and bypass
      if(isAdmin){
        return;
      }

      // TODO: 

      if(!userHasAccess){
        this.redirectToHome();
      }
    }
    else{
      // anyone not logged in will be redirected
      this.redirectToHome();
    }
  }

  public redirectIfNotAssociated3plData(associated3plOfThisPage: number){
    var userHasAccess = false;
    var isAdmin = false;
    
    var isLoggedIn = this.tokenStorageService.isLoggedIn();
    if(isLoggedIn){
      var user = this.tokenStorageService.getUser();

      isAdmin = user.roles.includes('Admin');

      // admins get access to everything and bypass
      if(isAdmin){
        return;
      }

      if(associated3plOfThisPage == user.client3plId){
        userHasAccess = true;
      }

      if(!userHasAccess){
        this.redirectToHome();
      }
    }
    else{
      // anyone not logged in will be redirected
      this.redirectToHome();
    }
  }

  private redirectToHome(): void{
    this.alertService.alert(  new alert('Unable to access page. Please try again or contact an Administrator for access'));        
    this.router.navigate(['/']);
  }
}
