import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService } from 'src/app/lib/alerts/alert.service';
import { WeValidatorPatterns } from 'src/app/_helpers/weValidatorPatterns.consts';
import { ProductsService } from '../../products.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private alertService: AlertService
    ) {}

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;
        
        // password not required in edit mode
        const passwordValidators = [Validators.minLength(6)];
        if (this.isAddMode) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            barcode: ['', Validators.required],
            rate: ['', [Validators.required, Validators.pattern(WeValidatorPatterns.DECIMAL_POSITIVE_ZERO_TO_TWO_DECIMAL_PLACES)]], // only accept $
        }, {
            validator: null
        });

        if (!this.isAddMode) {
        this.alertService.warn('is edit mode');
            this.productService.getById(this.id)
                .pipe(first())
                .subscribe(x => this.form.patchValue(x.data));
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createProduct();
        } else {
            this.updateProduct();
        }
    }

    cancel(){
    if(confirm('Are you sure you want to discard all your changes?')){
        this.router.navigate(['/products']);
    }
    }

    private createProduct() {
        console.log('create called. form vals:', this.form.value);

        this.productService.create(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Product added', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(JSON.stringify(error));
                    this.loading = false;
                }
            });
    }

    private updateProduct() {
    this.form.value.id = this.id;
    
        this.productService.update(this.id, this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Product updated', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(JSON.stringify(error));
                    this.loading = false;
                }
            });
    }
}
