
<h1>3PL Clients</h1>

<button mat-raised-button color="primary" (click)="goToAddClient()">
  Add Client
</button>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-wrapper">
    <!-- Id Column -->
    <ng-container matColumnDef="simpleWms3plConfigId">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> 
          <a [routerLink]="[element.simpleWms3plConfigId]">{{element.simpleWms3plConfigId}} </a>
        </td>
    </ng-container>
  
    <!-- name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> 
        <a [routerLink]="[element.simpleWms3plConfigId]">{{element.name}} </a> 
      </td>
    </ng-container>
  
    <!-- Simple WMS access Column -->
    <ng-container matColumnDef="canAccessPortal">
      <th mat-header-cell *matHeaderCellDef> Can Access Simple WMS </th>
      <td mat-cell *matCellDef="let element"> {{element.canAccessPortal}} </td>
    </ng-container>
      
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>