import { Component, OnInit } from '@angular/core';
import { CardStore } from './models/card-store';
import { ListSchema } from './models/list-schema';

@Component({
  selector: 'app-we-boards',
  templateUrl: './we-boards.component.html',
  styleUrls: ['./we-boards.component.css']
})
export class WeBoardsComponent implements OnInit {
  cardStore: CardStore;
  lists: ListSchema[];

  constructor(){

  }

  setMockData(): void {
    this.cardStore = new CardStore();
    const lists: ListSchema[] = [
      {
        name: 'To Do',
        cards: []
      },
      {
        name: 'Doing',
        cards: []
      },
      {
        name: 'Done',
        cards: []
      }
    ]
    console.log('lists', lists);
    this.lists = lists;
  }

  ngOnInit() {
    console.log('initing 1');
    this.setMockData();
  }

}
