<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <mat-list-item *ngIf="(isClient || isAdmin || isWarehouseTeam) && isLoggedIn" class="parent">
        <a routerLink="orders-outbound"
          (click)="showOutboundSubmenu = !showOutboundSubmenu">
          <i class="fa fa-shopping-cart"></i> 
          &nbsp; Outbound Orders
        </a>
        
				<mat-icon class="menu-button" 
          [ngClass]="{'fa-rotate-180' : showOutboundSubmenu}" 
          (click)="showOutboundSubmenu = !showOutboundSubmenu">expand_more</mat-icon>
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showOutboundSubmenu}" *ngIf="showOutboundSubmenu && isLoggedIn">
        <a mat-list-item  routerLink="orders-outbound">
          <i class="fa fa-list"></i> 
          View
        </a>        
        <a mat-list-item  routerLink="orders-outbound/new">
          <i class="fa fa-plus-circle"></i> 
          Create
        </a>
        <a mat-list-item  routerLink="orders-outbound/saved-recipients">
          <i class="fa fa-save"></i> 
          My Recipients
        </a>
      </div>


      <a *ngIf="false && (isAdmin || isWarehouseTeam) && isLoggedIn" mat-list-item routerLink="dashboard"><i class="fa fa-battery-three-quarters"></i> &nbsp; Dashboard</a>
      <a *ngIf="(isClient || isAdmin || isWarehouseTeam) && isLoggedIn" mat-list-item routerLink="inventory"><i class="fa fa-cubes"></i> &nbsp; Inventory</a>
      <a *ngIf="false && isAdmin" mat-list-item routerLink="products"><i class="fa fa-cube"></i> &nbsp; Products</a>
      <a *ngIf="false && isAdmin" mat-list-item routerLink="analytics"><i class="fa fa-search-plus"></i> &nbsp; Analytics</a>



      <br  *ngIf="isClient && isLoggedIn" />      
      <mat-list-item *ngIf="isClient && isLoggedIn" class="parent">
        <a
          (click)="showHelpSubmenu = !showHelpSubmenu">
          <i class="fa fa-question-circle"></i> 
          &nbsp; Help
        </a>
        
				<mat-icon class="menu-button" 
          [ngClass]="{'fa-rotate-180' : showHelpSubmenu}" 
          (click)="showHelpSubmenu = !showHelpSubmenu">expand_more</mat-icon>
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showHelpSubmenu}" *ngIf="showHelpSubmenu && isLoggedIn">
        <a *ngIf="isClient && isLoggedIn" 
          mat-list-item 
          href="mailto:Ops@warehousingetc.com?subject=Help Request - Operations&body=How can we help? Please attach purchase orders, labels or other helpful details.">
          <i class="fa fa-question-circle"></i>&nbsp;Operations Help
        </a>

        <a  *ngIf="isClient && isLoggedIn"
            mat-list-item 
            href="mailto:IT@warehousingetc.com?subject=Help Request - Client Portal&body=Please describe your issue in detail. Include screenshots and links when possible.">
          <i class="fa fa-info-circle"></i>&nbsp;IT Help
        </a>
      </div>



      <br *ngIf="(isAdmin || isWarehouseTeam) && isLoggedIn"/>
      <mat-list-item *ngIf="(isAdmin || isWarehouseTeam) && isLoggedIn" class="parent">
        <a routerLink="warehouse/outbounds"
          (click)="showWarehouseSubmenu = !showWarehouseSubmenu">
          <i class="fa fa-building-o"></i> 
          &nbsp; Warehouse Tools
        </a>
        
				<mat-icon class="menu-button" 
          [ngClass]="{'fa-rotate-180' : showWarehouseSubmenu}" 
          (click)="showWarehouseSubmenu = !showWarehouseSubmenu">expand_more</mat-icon>
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showWarehouseSubmenu}" *ngIf="showWarehouseSubmenu && isLoggedIn">
        <a mat-list-item  routerLink="warehouse/outbounds">
          <i class="fa fa-th-large"></i> 
          Outbound Orders
        </a>
        <h2 *ngIf="false && isAdmin">&nbsp;&nbsp;&nbsp;&nbsp;WH Manager</h2>
        <a *ngIf="isLoggedIn && isAdmin" mat-list-item routerLink="warehouse-manager"><i class="fa fa-tasks"></i> &nbsp; WH Jobs </a>
      </div>
      


      <br *ngIf="(isAdmin || isWarehouseTeam) && isLoggedIn"/>
      <mat-list-item *ngIf="(isAdmin || isWarehouseTeam) && isLoggedIn" class="parent">
        <a routerLink="warehouse/outbounds"
          (click)="showConfigSubmenu = !showConfigSubmenu">
          <i class="fa fa-cogs"></i> 
          &nbsp; Configuration
        </a>
        
				<mat-icon class="menu-button" 
          [ngClass]="{'fa-rotate-180' : showConfigSubmenu}" 
          (click)="showConfigSubmenu = !showConfigSubmenu">expand_more</mat-icon>
      </mat-list-item>
      <div class="submenu" [ngClass]="{'expanded' : showConfigSubmenu}" *ngIf="showConfigSubmenu && isLoggedIn">
        <a *ngIf="isLoggedIn && isAdmin" mat-list-item routerLink="clients"><i class="fa fa-id-card"></i> &nbsp; Client 3PLs </a>
        <a *ngIf="isLoggedIn && isAdmin" mat-list-item routerLink="users"><i class="fa fa-users"></i> &nbsp; Users</a>
      </div>


      

      <h2 *ngIf="false && isAdmin">&nbsp;&nbsp;&nbsp;&nbsp;PoCs</h2>
      <a *ngIf="false && isAdmin" mat-list-item routerLink="demo/boards"><i class="fa fa-columns"></i> &nbsp; Board Demo</a>
      <a *ngIf="false && isAdmin" mat-list-item routerLink="admin/form-builder"><i class="fa fa-wpforms"></i> &nbsp; Form Builder Admin</a>

      <br/>

      <a *ngIf="!isLoggedIn" mat-list-item routerLink="login"><i class="fa fa-sign-in"></i> &nbsp; Login</a>
      <a *ngIf="isLoggedIn" mat-list-item (click)="logout()" ><i class="fa fa-sign-out"></i> &nbsp; Logout</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <div class="logo">
        <a href="#" class="simple-text">
            <div class="logo-img">
                <img src="/assets/warehousing-etc-omnichannel-logistics-consultant-providers-logo-transparent-500-x-200.png"/>
            </div>
        </a>
      </div>

      <!--
      <div class="inline btn-warning my-notifications">
        <i class="fa fa-bell"></i>
        Notifications
      </div>
      <div class="inline btn-danger my-tasks">
        <i class="fa fa-tasks"></i>
        My Tasks
      </div>
      -->

      <div class="user-wrapper">
        <a routerLink="users/my-profile/{{id}}">
          <span class="welcome-message">Hello, {{firstName}}</span>
          <img class="user-profile-image" src="/assets/wms-theme/default_user_image.png" />
        </a>
      </div>
      
    </mat-toolbar>

    <!-- Add Content Here -->
    <div class="wms-app-wrapper">
      <alert></alert>
      <router-outlet></router-outlet>      
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
