import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { InventoryService } from './inventory.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  inventory: any[];
  displayedColumns: string[] = ['name', 'sku', 'quantityOnHand', 'quantityAvailableToPromise', 'reorderPoint'];
  dataSource:MatTableDataSource<any>;
  lastUpdated: Date;


  constructor(private inventoryService: InventoryService
        , private alertService: AlertService) { }

  ngOnInit(): void {
    this.inventoryService.getAll()
      .subscribe(result =>
        {
          this.inventory = result.inventory;
          this.lastUpdated = result.lastUpdatedTime;
          this.dataSource = new MatTableDataSource(this.inventory);
        },
        error =>
        {
          this.alertService.error(
            'Unexpected error getting inventory. message:<br/><br/>' 
          + JSON.stringify(error));
        })
  }

  downloadInventory(): void{
    this.inventoryService.downloadAll()
          .subscribe((data) => {
            var downloadURL = window.URL.createObjectURL(data);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "export_inventory_" + new Date().toLocaleString() + ".csv";
            link.click();
          
          });
  }

}
