import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { MyProfileService } from '../my-profile/my-profile.service';
import { ChangePasswordService } from './change-password.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  id: string;
  userGroup: FormGroup;
  userSubmitted = false;
  loading: boolean = false;

  constructor(
    private userRoleService: UserRoleService,
    private changePasswordService: ChangePasswordService,
    private formBuilder: FormBuilder,
    private myProfileService: MyProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
    ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.userRoleService.RedirectIfNotCurrentUserAccess(this.id);

    this.userGroup = this.formBuilder.group({
      currPassword: ['', Validators.required],
      newPassword1: ['', Validators.required],
      newPassword2: ['', Validators.required]
    }, {
        validator: null
    });

    this.loadPageData();
  }

  private loadPageData(): void{        
      // nothing to load for password
  }

  get ug() { return this.userGroup.controls; }

  onUserGroupSubmit(){
    this.userSubmitted = true;    

    if(this.userGroup.invalid){
        return;
    }

    var pass1 = this.userGroup.controls['newPassword1'].value;
    var pass2 = this.userGroup.controls['newPassword2'].value;

    if(pass1 !== pass2){
      alert('NEW PASSWORDS DO NOT MATCH. Please retry.');
      return;
    }
    
    this.loading = true;
    this.changePasswordService.changePassword(this.id, this.userGroup.value) 
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Successfully changed password', { keepAfterRouteChange: true });
                //this.router.navigate(['../'], { relativeTo: this.route });
                this.userSubmitted = false;
                this.loading = false;
            },
            error: error => {
                this.alertService.error('FAILED to change password. Retry or contact support for help.  Details: ' + error);
                this.loading = false;
                //this.router.navigate(['../'], { relativeTo: this.route });
            }
        });
  }


  
  cancel(){
    this.router.navigate(['/']);
  }
}
