import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class SavedRecipientsService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'SavedRecipient/';
   }

   
  getById(id: string): Observable<any> {
    return this.http.get(this.API_URL + id
    , { responseType: 'json' });
  }

  
  getActive(): Observable<any> {
    return this.http.get(this.API_URL + "active"
    , { responseType: 'json' });
  }

  findRecipients(
    filterSearchText = ''
    , sortField: string, sortOrder: string
    ,pageNumber: number, pageSize: number):  Observable<any> {

    return this.http.get(this.API_URL, {
        params: new HttpParams()
            .set('filterSearchText', filterSearchText)
            .set('sortField', sortField)
            .set('sortOrder', sortOrder)
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
    }).pipe(
      // return the whole payload. we care about res["data"] as the list of all and res["totalCount"] which is all possible results in pager
        map(res =>  res)
    );
  }

  create(recipient: any) {
    return this.http.post(this.API_URL, recipient);
  }
  
  update(id: string, recipient: any) {
    return this.http.put(this.API_URL + id, recipient);
  }
}
