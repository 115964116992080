import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { UserComponent } from './features/users/user/user.component';
import { FormBuilderAdminComponent } from './features/form-builder-admin/form-builder-admin.component';
import { BoardsDemoComponent } from './features/boards-demo/boards-demo.component';
import { InventoryComponent } from './features/inventory/inventory.component';
import { ProductsComponent } from './features/products/products.component';
import { AnalyticsComponent } from './features/analytics/analytics.component';
import { LoginComponent } from './features/login/login.component';
import { RegisterComponent } from './features/register/register.component';
import { BoardAdminComponent } from './features/board-admin/board-admin.component';
import { BoardModeratorComponent } from './features/board-moderator/board-moderator.component';
import { ProfileComponent } from './features/profile/profile.component';
import { ProductComponent } from './features/products/product/product/product.component';
import { ImportProductsComponent } from './features/products/import-products/import-products.component';
import { OrdersOutboundComponent } from './features/orders-outbound/orders-outbound.component';
import { OutboundOrderComponent } from './features/orders-outbound/outbound-order/outbound-order.component';
import { UsersComponent } from './features/users/users.component';
import { AssociatedClient3plComponent } from './features/associated-client3pls/associated-client3pl/associated-client3pl.component';
import { AssociatedClient3plsComponent } from './features/associated-client3pls/associated-client3pls.component';
import { ChangePasswordComponent } from './features/users/user/change-password/change-password.component';
import { MyProfileComponent } from './features/users/user/my-profile/my-profile.component';
import { WarehouseManagerComponent } from './features/warehouse-manager/warehouse-manager.component';
import { SavedRecipientsComponent } from './features/orders-outbound/saved-recipients/saved-recipients.component';
import { SavedRecipientComponent } from './features/orders-outbound/saved-recipients/saved-recipient/saved-recipient.component';
import { SsOrderComponent } from './features/orders-outbound/outbound-order/ss-order/ss-order.component';
import {WarehouseOutboundsComponent} from './features/warehouse-outbounds/warehouse-outbounds.component'

// import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }

  ////// BEGIN CLIENT PORTAL pages
  ,
  { path: 'orders-outbound', component: OrdersOutboundComponent } 
  ,
  { path: 'orders-outbound/saved-recipients', component:  SavedRecipientsComponent }
  ,
  { path: 'orders-outbound/saved-recipients/:id', component:  SavedRecipientComponent }
  ,
  { path: 'orders-outbound/new', component: OutboundOrderComponent } 
  ,
  { path: 'orders-outbound/:id', component: OutboundOrderComponent }
  ,
  { path: 'orders-outbound/ss/:id', component: SsOrderComponent }
  ,
  { path: 'inventory', component: InventoryComponent }
  ////// END CLIENT PORTAL pages


  ////// BEGIN WAREHOUSE MODULES
  ,
  { path: 'warehouse/outbounds', component: WarehouseOutboundsComponent }
  //////  END WAREHOUSE MODULES


  ////// BEGIN ADMIN pages
  ,
  { path: 'warehouse-manager', component: WarehouseManagerComponent }
  ,
  { path: 'clients', component: AssociatedClient3plsComponent }
  ,
  { path: 'clients/:id', component: AssociatedClient3plComponent }
  ,
  { path: 'users', component: UsersComponent }
  ,
  { path: 'users/:id', component: UserComponent }
  ,
  { path: 'users/change-password/:id', component: ChangePasswordComponent }
  ,
  { path: 'users/my-profile/:id', component: MyProfileComponent }
  ////// END ADMIN pages



  ////// BEGIN TEST pages  (these pages dont do anything functional in Simple WMS today)
  ,
  { path: 'dashboard', component: DashboardComponent }
  ,
  { path: 'analytics', component: AnalyticsComponent }
  ,
  { path: 'demo/boards', component: BoardsDemoComponent }
  //,{ path: 'login', component: LoginComponent }
  ,
  { path: 'admin/form-builder', component: FormBuilderAdminComponent }
  
  // TODO: delete mod and admin after finish auth from https://bezkoder.com/angular-11-jwt-auth/
  ,
  {
    path: 'mod', component: BoardModeratorComponent
  }
  ,
  { path: 'admin', component: BoardAdminComponent }

  ,
  { path: 'products', component: ProductsComponent }
  ,
  { path: 'products/import', component: ImportProductsComponent }
  ,
  { path: 'products/:id', component: ProductComponent }

  ////// END TEST pages


  
  ////// BEGIN USER PROFILE and Login pages
  ,{ path: 'login', component: LoginComponent }
  ,{ path: 'register', component: RegisterComponent }
  ,{ path: 'profile', component: ProfileComponent }
  ////// END USER PROFILE and Login pages

  // , {
  //   path: '',
  //   component: AdminLayoutComponent,
  //   children: [{
  //     path: '',
  //     loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  //   }]
  // }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
