<h1>Dashboard</h1>

<ag-grid-angular
    style="width: 80%; height: 500px;"
    class="ag-theme-alpine"
    [pivotMode]="true"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [sideBar]="sideBar"
    [columnDefs]="columnDefs"
>
</ag-grid-angular>