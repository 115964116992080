<h1 class="header" *ngIf="isCreateMode">Create 3PL Client: <i>{{cg.name.value}}</i></h1>
<h1 class="header" *ngIf="!isCreateMode">Edit 3PL Client Config: <i>{{cg.name.value}}</i></h1>

<form [formGroup]="clientGroup" (ngSubmit)="onClientGroupSubmit()">
    <h2>Basic Info</h2>
    <div class="form-row">
        <div class="form-group col">
            <label>Name</label>
            <input type="text" placeholder="Client Name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': clientSubmitted && cg.name.errors }" />
            <div *ngIf="clientSubmitted && cg.name.errors" class="invalid-feedback">
                <div *ngIf="cg.name.errors.required">Client name is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label>
                <input formControlName="canAccessPortal" class="form-control eighty-percent-width" type="checkbox" />
                Can Access Simple WMS
            </label>
        </div>
    </div>    
    
    <div class="form-row">
        <div class="form-group col">
            <h2>OpenBoxes Config</h2>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>OpenBoxes Organization ID</label>
            <input type="text" placeholder="ID" formControlName="openBoxesId" class="form-control" [ngClass]="{ 'is-invalid': clientSubmitted && cg.openBoxesId.errors }" />
            <div *ngIf="clientSubmitted && cg.openBoxesId.errors" class="invalid-feedback">
                <div *ngIf="cg.openBoxesId.errors.required">OpenBoxes ID is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Product Tag ID</label>
            <input type="text" placeholder="Product Tag ID" formControlName="productTagId" class="form-control" [ngClass]="{ 'is-invalid': clientSubmitted && cg.productTagId.errors }" />
            <div *ngIf="clientSubmitted && cg.productTagId.errors" class="invalid-feedback">
                <div *ngIf="cg.productTagId.errors.required">Product Tag ID is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Default Parcel Origin ID - i.e. items ship from Tampa-001 Warehouse (ID=1)</label>
            <input type="text" placeholder="Origin ID" formControlName="defaultOpenBoxesParcelOriginId" class="form-control" [ngClass]="{ 'is-invalid': clientSubmitted && cg.defaultOpenBoxesParcelOriginId.errors }" />
            <div *ngIf="clientSubmitted && cg.defaultOpenBoxesParcelOriginId.errors" class="invalid-feedback">
                <div *ngIf="cg.defaultOpenBoxesParcelOriginId.errors.required">Default Origin ID is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Default Parcel Destination (End Consumer) ID</label>
            <input type="text" placeholder="Destination ID" formControlName="defaultOpenBoxesParcelDestinationId" class="form-control" [ngClass]="{ 'is-invalid': clientSubmitted && cg.defaultOpenBoxesParcelDestinationId.errors }" />
            <div *ngIf="clientSubmitted && cg.defaultOpenBoxesParcelDestinationId.errors" class="invalid-feedback">
                <div *ngIf="cg.defaultOpenBoxesParcelDestinationId.errors.required">Default Destination ID is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <h2>Features</h2>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">            
            <label>
                <input formControlName="manualOrdersEnabled" class="form-control eighty-percent-width" type="checkbox" 
                    [ngClass]="{ 'is-invalid': clientSubmitted && cg.manualOrdersEnabled.errors }"/>
                Place Manual Outbound Order
            </label>
            
            <div *ngIf="clientSubmitted && cg.manualOrdersEnabled.errors" class="invalid-feedback">
                <div *ngIf="cg.manualOrdersEnabled.errors.required">Manual OrdersEnabled is required</div>
            </div>
        </div>
    </div>
    
    <div class="form-row">
        <div class="form-group col">            
            <label>
                <input formControlName="shipStationEnabled" class="form-control eighty-percent-width" type="checkbox" 
                    [ngClass]="{ 'is-invalid': clientSubmitted && cg.shipStationEnabled.errors }"/>
                ShipStation Store Integration Enabled
            </label>
            
            <div *ngIf="clientSubmitted && cg.shipStationEnabled.errors" class="invalid-feedback">
                <div *ngIf="cg.shipStationEnabled.errors.required">ShipStation Store Integration setting is required</div>
            </div>
        </div>
    </div>

    <div class="form-row" *ngIf="cg.shipStationEnabled.value">
        <div class="form-group col">            
            <label>
                ShipStation Store Integration ID (Storefront ID in SS - MUST BE AN INTEGER VALUE) ; get from Our Helper API @ /api/v1/ShipStationStoreDetails
            </label>
            <input formControlName="shipStatonSimpleIntegrationStoreId" class="form-control" type="text" 
                [ngClass]="{ 'is-invalid': clientSubmitted && cg.shipStatonSimpleIntegrationStoreId.errors }"/>
        </div>
    </div>


         
    
    <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Submit
    </button>
    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
</form> 