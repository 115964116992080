import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { SavedRecipientsDataSource } from '../dataSource/saved-recipients.data-source';
import { SavedRecipientsService } from './saved-recipients.service';

@Component({
  selector: 'app-saved-recipients',
  templateUrl: './saved-recipients.component.html',
  styleUrls: ['./saved-recipients.component.css']
})
export class SavedRecipientsComponent implements OnInit {
  displayedColumns: string[] = ['id', 'displayName', 'isActive'];
  dataSource: SavedRecipientsDataSource;
// mostly setup table from tutorial: https://blog.angular-university.io/angular-material-data-table/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filterKeyword: string = '';

  constructor(private savedRecipientService: SavedRecipientsService
    , private router: Router
      , private alertService: AlertService) { }

  ngOnInit(): void {

  }

  
  ngAfterViewInit() {
    // fill table with default pager settings on load
    this.loadListOfData();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => this.resetPagingToFirstPage());
    
    // when sort order or page details change, call server to get next dataset
    merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadListOfData())
        )
        .subscribe();
  }

  
  goToAddRecipient(){
    // go to edit page without an ID to trigger Add
    this.router.navigate(['/orders-outbound/saved-recipients', '']);
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterKeyword = filterValue?.trim()?.toLowerCase();

    // when more than 3 chars, get new data from server
    if(this.filterKeyword?.length >= 3){
      this.resetPagingToFirstPage();
      this.loadListOfData();
    }
  }

  private resetPagingToFirstPage(){
    // reset the paginator after sorting
    this.paginator.pageIndex = 0
  }

  private loadListOfData(): void{
    // UI count starts at 0, but backend starts at 1
    let pageNumber =  this.paginator.pageIndex + 1;
    
    this.dataSource = new SavedRecipientsDataSource(this.savedRecipientService, this.alertService);
    this.dataSource.setPaginator(this.paginator);
    
    this.dataSource.loadRecipients(this.filterKeyword, this.sort.active, this.sort.direction, pageNumber, this.paginator.pageSize);
  }
  

}
