import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfigService } from 'src/app/_services/global-config.service';
import { DetailedUser } from './models/detailedUser';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'user/';
   }

   getById(id: string): Observable<any> {
    return this.http.get(this.API_URL + id
    , { responseType: 'json' });
  }
   
  getAllUsers(pageNumber: number = 1, pageSize: number = 250): Observable<any> {
    return this.http.get(this.API_URL + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize
    , { responseType: 'json' });
  }

  create(user: any) {
    return this.http.post(this.API_URL, user);
  }

  update(id: string, product: DetailedUser) {
    return this.http.put(this.API_URL + id, product);
  }
}
