import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { User } from './models/user';
import { UsersService } from './users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  usersOnThisPage: User[];
  displayedColumns: string[] = ['id', 'fullName', 'email', 'phoneNumber', 'associated3plClient', 'isActive'];
  dataSource:any;

  constructor(private userService: UsersService,
    private userRoleService: UserRoleService,
    private router: Router,
    private alertService: AlertService) { }


  ngOnInit(): void {
    this.userRoleService.RedirectIfNotAdminUser();
      
    this.userService.getAllUsers()
        .subscribe(
          result =>
          {
            this.usersOnThisPage = result.data;          
            this.dataSource = new MatTableDataSource(this.usersOnThisPage);
          },
          error =>
          {
            this.alertService.error(
              'Unexpected error getting users. message:<br/><br/>' 
            + JSON.stringify(error));
          }
        )
  }
  
  goToAddUser(){
    // go to edit page without an ID to trigger Add
    this.router.navigate(['/users', '']);
  }
}
