import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class WarehouseOutboundsService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'WarehouseOutboundOrders/';
   }

   
  getOrdersByStatusGroup(
      orderStatusGroup = '' /* ON_HOLD, TO_SHIP, SHIPPED, CANCELLED */
    ,pageNumber: number, pageSize: number
    ):  Observable<any> 
    {

        return this.http.get(this.API_URL, {
            params: new HttpParams()
            .set('orderStatusGroup', orderStatusGroup)
            .set('PageNumber', pageNumber.toString())
            .set('PageSize', pageSize.toString())
        }).pipe(
          // return the whole payload. we care about res["data"] as the list of all and res["totalCount"] which is all possible results in pager
            map(res =>  res)
        );
  }

}
