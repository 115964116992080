import { Component, Input, OnInit } from "@angular/core";
import { CardSchema } from '../models/card-schema';

@Component({
  selector: 'app-we-card',
  templateUrl: './we-card.component.html',
  styleUrls: ['./we-card.component.css']
})
export class WeCardComponent implements OnInit {
  @Input() card: CardSchema;
  
  constructor() {

  }

  ngOnInit() {
    
  }

  dragStart(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
} 