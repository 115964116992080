import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/_services/authentication-management/token-storage.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'wms-nav',
  templateUrl: './wms-nav.component.html',
  styleUrls: ['./wms-nav.component.css']
})
export class WmsNavComponent implements OnInit {
  isLoggedIn: boolean = false;
  isAdmin: boolean = false;
  isWarehouseTeam: boolean = false;
  isClient: boolean = false;
  id: string;
  userName: string = '';
  firstName: string = '';

  showOutboundSubmenu: boolean = false;
  showWarehouseSubmenu: boolean = false;
  showHelpSubmenu: boolean = false;
  showConfigSubmenu: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver
    , private tokenStorageService: TokenStorageService
    , private router: Router) {
      this.router.events
        .pipe(
          filter( event =>event instanceof NavigationStart)
        )
        .subscribe((event: NavigationStart)=>{
          //console.log('navigation route change detected.');

          this.isLoggedIn = this.tokenStorageService.isLoggedIn();
          if(this.isLoggedIn){
            var user = this.tokenStorageService.getUser();
            console.log('navigation route change user:', user);

            this.userName = user.userName;
            this.id = user.id;
            this.firstName = user.firstName;

            this.isAdmin = user.roles.includes('Admin');
            this.isClient = user.roles.includes('Basic');//('Client'); // TODO: make case-insensitive
            this.isWarehouseTeam = user.roles.includes('Warehouse');
          }
        }
      )
    }
  
  
  
  ngOnInit(): void {

    
    
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
}
