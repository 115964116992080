import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private API_URL: string;
  private API_URL_DOWNLOAD: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'Inventory/';
      this.API_URL_DOWNLOAD = this.globalConfigService.getWebServiceEndpoint() 
                      + 'InventoryDownload/';
   }
   
  getAll(): Observable<any> {
    return this.http.get(this.API_URL
        , { responseType: 'json' });
  }
  
  downloadAll(): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(this.API_URL_DOWNLOAD, httpOptions);
  }
}
