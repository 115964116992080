import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConfigService } from '../global-config.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'user/';
   }

  getModeratorBoard(): Observable<any> {
    return this.http.get(this.API_URL + 'mod'
    , { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(this.API_URL + 'admin'
    , { responseType: 'text' });
  }
}