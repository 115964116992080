import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private API_URL_UPLOAD: string;
  private API_URL_DOWNLOAD: string;
  private API_URL_DELETE: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL_UPLOAD = this.globalConfigService.getWebServiceEndpoint() 
                      + 'FileUpload/';
      this.API_URL_DOWNLOAD = this.globalConfigService.getWebServiceEndpoint() 
                      + 'FileDownload/';
      this.API_URL_DELETE = this.globalConfigService.getWebServiceEndpoint() 
                      + 'FileArchive/';
   }


  uploadFiles(fileType: string, formData: any): Observable<any> {
    return this.http.post(this.API_URL_UPLOAD + '?weFileType=' + fileType, formData, 
      {reportProgress: true, observe: 'events'});      
  }

  deleteFile(id: string): Observable<any> {
    return this.http.delete(this.API_URL_DELETE + id);
  }

  // MUST add hidden, html <a> to page to push download. See other usage. 
  downloadFile(id: string) : Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get(this.API_URL_DOWNLOAD + id, httpOptions);
  }
}
