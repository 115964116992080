import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDateRangePicker, MatEndDate, MatStartDate } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap  } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { FileUploadService } from '../file-upload/file-upload.service';
import { WarehouseOutboundOrdersDataSource } from './dataSource/warehouse-outbound-orders.data-source';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { WarehouseOutboundsService } from './warehouse-outbounds.service'

@Component({
  selector: 'app-warehouse-outbounds',
  templateUrl: './warehouse-outbounds.component.html',
  styleUrls: ['./warehouse-outbounds.component.css']
})
export class WarehouseOutboundsComponent implements OnInit {
  OutboundOrdersOnThisPage: any[];
  displayedColumns: string[] = ['id', 'orderStatus', 'name', 'orderSource', 'dateRequested', 'itemsOrdered', 'totalUnits'];
  dataSource: WarehouseOutboundOrdersDataSource;
  // mostly setup table from tutorial: https://blog.angular-university.io/angular-material-data-table/
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userRoleService: UserRoleService
    , private warehouseOutboundsService: WarehouseOutboundsService
    , private fileService: FileUploadService
    , private alertService: AlertService) { }

  ngOnInit(): void {
    // NOTE: in future, allow Warehouse Workers access too - rk 20229316 - not a feature today
    this.userRoleService.RedirectIfNotAdminUser();

    // init with defualt values
    this.loadAllOrdersByGrouping();


        // reset the paginator after sorting
        //this.sort.sortChange.subscribe(() => this.resetPagingToFirstPage());
    
        // // when sort order or page details change, call server to get next dataset
        // merge(this.sort.sortChange, this.paginator.page)
        //     .pipe(
        //         tap(() => this.loadAllOrdersByGrouping())//(this.paginator.pageIndex, this.paginator.pageSize))
        //     )
        //     .subscribe();
  }

  
  resetPagingToFirstPage(){
    // reset the paginator after sorting
    this.paginator.pageIndex = 0
  }

  private loadAllOrdersByGrouping(pageNumber: number = 0, pageSize: number = 250): void{
    // TODO: pageNumber and pageSize from the dataSource and custom component, like WarehouseOutboundOrdersDataSource
    //this.warehouseOutboundsService.getOrdersByStatusGroup('SHIPPED', this.paginator.pageIndex, this.paginator.pageSize)
          
    pageNumber = pageNumber + 1;
    
    this.dataSource = new WarehouseOutboundOrdersDataSource(this.warehouseOutboundsService, this.alertService);
    this.dataSource.setPaginator(this.paginator);


    this.dataSource.loadOrders
          ( "TO_SHIP"
            , pageNumber
            , pageSize
          );
  }


  downloadFile(blobId){
    //console.log('download');
    this.fileService.downloadFile(blobId)
    .subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'Attachment_download_WE';
      link.click();
    
    });
  }


}
