import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { TokenStorageService } from 'src/app/_services/authentication-management/token-storage.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { LuvSubnationalDivisionService } from 'src/app/_services/luv-subnational-division.service';
import { SavedRecipientsService } from '../saved-recipients.service';

@Component({
  selector: 'app-saved-recipient',
  templateUrl: './saved-recipient.component.html',
  styleUrls: ['./saved-recipient.component.css']
})
export class SavedRecipientComponent implements OnInit {
  id: string;
  isCreateMode: boolean;
  addressGroup: FormGroup;
  submitted = false;
  loading = false;
  allStates: any[];
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private recipientService: SavedRecipientsService,
    private userRoleService: UserRoleService,
    private alertService: AlertService,
    private subnationalDivisionService: LuvSubnationalDivisionService
    ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isCreateMode = !this.id;

    this.addressGroup = this.formBuilder.group({
      id: [''],
      displayName: ['', Validators.required],
      description: [''],
      client3plId: [''], // never shown on UI; backend controls for current logged in User      
      isActive: [true, Validators.required],

      // address fields
      addressId: [''],
      recipient1: ['', Validators.required],
      recipient2: [''],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      city: ['', Validators.required],
      state: ['', [Validators.required, Validators.maxLength(3)]],
      zipCode: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],
    }, {
        validator: null
    });

    this.loadPageData();
  }

  private loadPageData(): void{    
    
    if(!this.isCreateMode){
      this.recipientService.getById(this.id)
        .pipe(first())
        .subscribe(x => {            
                this.addressGroup.patchValue(x.data);
                // ENFORCE SECURITY ON THE PAGE
                this.userRoleService.redirectIfNotAssociated3plData(x.data.client3plId);
        });
    }

    
    this.subnationalDivisionService.getAll()
      .pipe(first())
      .subscribe(x => {
          this.allStates = x;
      })
  }

  get ag() { return this.addressGroup.controls; }


  onAddressGroupSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    if (this.addressGroup.invalid) {
        return;
    }

    this.loading = true;
    if (this.isCreateMode) {
        this.create();
    } else {
      this.update();
    }
  }

  private create(){    
    this.recipientService.create(this.addressGroup.value)
      .pipe(first())
      .subscribe({
        next: () => {
            this.alertService.success('Recipient added', { keepAfterRouteChange: true });
            this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: error => {
            this.alertService.error('Unexpected error, your recipient may have been partially created or not created at all. Contact support for help.  Details: ' + error?.message);
            this.loading = false;
        }
    });
  }

  private update() {    
    this.recipientService.update(this.id, this.addressGroup.value)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Recipient updated', { keepAfterRouteChange: true });
                this.router.navigate(['../'], { relativeTo: this.route });
            },
            error: error => {
                this.alertService.error('Unexpected error, your recipient may have been partially updated or not created at all. Contact support for help.  Details: ' + error?.message);
                this.loading = false;
            }
        });
}
    
  cancel(){
    if(this.isCreateMode){
        if(confirm('Are you sure you want to discard all your changes?')){
            this.router.navigate(['/orders-outbound/saved-recipients']);
        }
    }else {
        this.router.navigate(['/orders-outbound/saved-recipients']);
    }
  }

}
