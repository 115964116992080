import { NullTemplateVisitor } from '@angular/compiler';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { catchError, first, map } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import { ProductsService } from 'src/app/features/products/products.service';
import { Product } from 'src/app/features/products/models/product';

import * as XLSX from 'xlsx';
import { AlertService } from '../alerts/alert.service';
import { SELECT_PANEL_INDENT_PADDING_X } from '@angular/material/select';
import { Router } from '@angular/router';
type AOA = any[][];

// started from https://stackblitz.com/edit/angular-read-and-export-excel-demo?file=src%2Fapp%2Fsheet%2Fsheet.component.ts
@Component({
  selector: 'app-import-sheet',
  templateUrl: './import-sheet.component.html',
  styleUrls: ['./import-sheet.component.css']
})
export class ImportSheetComponent implements OnInit {
  displayedColumns: string[] = [];
//  dataSource:any;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) importTableName: MatTable<any>;

  loading: boolean = false;


  constructor(
    private productService: ProductsService,
    private router: Router,
    private alertService: AlertService,
    private changeDetectorRefs: ChangeDetectorRef
    ) { }

  ngOnInit() {
  }

  data: AOA = null; //i.e. [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'data_extract_download_.xlsx';

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    const reader: FileReader = new FileReader();

    if (target.files.length !== 1){
      throw new Error('Cannot use multiple files');
    }

    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log('imported excel data:', this.data);

      var firstRow = this.data[0];
      this.displayedColumns = [];
      for(var col = 0; col < firstRow.length; col++){
        this.displayedColumns.push(firstRow[col]);
      }
      
      this.cleanseAndValidateRowsFromData();
      this.dataSource = new MatTableDataSource(this.data);
      // trigger the mat-table to update datasource
      this.importTableName.renderRows();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  import(): void{
    // TODO: call an import/save method defined in the user of this lib component

    // TODO: final front-end validation

    if(confirm('Are you sure you want to import all of these products?')){
      this.loading = true;

      // TODO: how can i wait for all asyncs to finish and then show a single success?
      // let allCreateRequests: any[];
      // let anySaveErrorsOccured: boolean = false;

      for(var i = 0; i < this.data.length; i++){
        var row = this.data[i][0];

        var newProduct : Product = new Product();
        newProduct.name = this.data[i][0].toString();
        newProduct.barcode = this.data[i][1].toString();
        newProduct.description = this.data[i][2].toString();
        newProduct.rate = this.data[i][3];

        this.productService.create(newProduct)        
          .pipe(first())
          .subscribe({
              next: () => {
                  this.alertService.success('Product added', { keepAfterRouteChange: true });
                  // TODO: join promises and show 1 success message?

                  //this.router.navigate(['../'], { relativeTo: this.route });
              },
              error: error => {
                  this.alertService.error(JSON.stringify(error));
                  this.loading = false;
              }
          });
      }

      // forkJoin(allCreateRequests)
      // .subscribe(
      //   next => {
      //     this.alertService.success('All products Saved. Redirecting.');
      //     this.router.navigate(['/products']);
      //   },
      //   error => this.alertService.error('At least one product could not be imported. Please go to the dashboard before continuing.') 
      //   );
    }
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  private cleanseAndValidateRowsFromData(){
    //remove the header from the source so is not duplicate
    this.data = this.data.slice(1);

    /// TODO: do more advanced validation

    //remove empty rows
    // TODO: support more robust and flexible logic and validation
    this.data = this.data.filter(row => row.length > 0);
  }

}
