import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { SsOrderService } from './ss-order.service';

@Component({
  selector: 'app-ss-order',
  templateUrl: './ss-order.component.html',
  styleUrls: ['./ss-order.component.css']
})
export class SsOrderComponent implements OnInit {
  id: string;
  order: any;

  constructor(private shipStationOrderService: SsOrderService,
    private userRoleService: UserRoleService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.populateOrderDetails();
  }

  populateOrderDetails(): void{
    
    this.shipStationOrderService.getById(this.id)
    .pipe(first())
    .subscribe(x => {
            console.log('x.data in SSOrder details load. Data: ' , x.data);
            this.order = x.data;
            
            // ENFORCE SECURITY ON THE PAGE
            this.userRoleService.redirectIfNotAssociated3plData(x.data.simpleWms3PlConfigId);
    });

  }
}
