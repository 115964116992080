<div class="container">
    <div class="row">
        <mat-progress-spinner mode="indeterminate">
        </mat-progress-spinner> 

            <!-- TODO: style? -->
        <p class="spinner-message" *ngIf="message && message !== ''">
            {{message}}
        </p>
    </div>
</div>