// ref https://bezkoder.com/angular-11-jwt-auth/
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConfigService } from '../global-config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private API_URL: string;
  
  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {
      this.API_URL = this.globalConfigService.getWebServiceEndpoint(true);
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(this.API_URL + 'identity/token', {
      Email: username,
      Password: password
    }, httpOptions);
  }

  
  testAuth(): Observable<any> {
    return this.http.get(this.API_URL + 'v1/Brand', httpOptions);
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(this.API_URL + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }
}
