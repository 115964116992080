<h1>Warehouse - Outbound Orders</h1>

<p>
    TODO: filters and all that jazz
</p>

<h2>To Ship</h2>
NOTE Sorting does not work right now, sorry.

<div class="table-container">
    <table mat-table [dataSource]="dataSource" 
            class="mat-elevation-z8 table-wrapper"
            matSort matSortActive="dateRequested" matSortDirection="desc" matSortDisableClear>
        <!-- Id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell  mat-sort-header *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> 
                <!-- Simple WMS orders have readonly forms to see details -->
                <a *ngIf="element.orderSource == 'Manually Entered'" 
                    target="_blank"
                    title="{{element.genericId}}" 
                    [routerLink]="['../../orders-outbound/' + element.genericId]">
                    #
                    {{ (element.genericId.length > 5)
                    ? '..' + (element.genericId | slice: (element.genericId.length-5))
                    : (element.genericId)
                    }}
                </a>

                <!-- ShipStation orders do note have details visible on a form -->
                <a *ngIf="element.orderSource != 'Manually Entered'" 
                    title="{{element.genericId}}" 
                    target="_blank"
                    class="shipstation-id"
                    [routerLink]="'../../orders-outbound/ss/' + element.genericId" >
                    #
                    {{ (element.genericId.length > 5)
                    ? '..' + (element.genericId | slice:(element.genericId.length-5))
                    : (element.genericId)
                    }}
                </a>
            </td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="orderStatus">
            <th mat-header-cell  mat-sort-header *matHeaderCellDef> Status</th>
            <td mat-cell *matCellDef="let element"> 
                <span class="order-status"><i>Status: &nbsp;</i> {{element.orderStatus}} </span>

                <div class="order-quick-links">
                    <h3>Quick Links</h3>

                    <ul>
                        <li>
                            <a  *ngIf="element.openBoxesId != null"
                                target="_blank"
                                title="{{element.openBoxesId}}" 
                                href="http://40.85.176.237:8080/openboxes/stockMovement/show/{{element.openBoxesId}}">
                                Go to OpenBoxes Order
                            </a>           
                            <span *ngIf="element.openBoxesId == null"
                                class="error">
                                No OpenBoxes Order Associated!
                            </span>
                        </li>


                        <li>

                            <!-- Simple WMS orders have readonly forms to see details -->
                            <a *ngIf="element.orderSource == 'Manually Entered'" 
                                target="_blank"
                                title="{{element.genericId}}" 
                                [routerLink]="['../../orders-outbound/' + element.genericId]">
                                Simple WMS Link (Client's reference)
                            </a>                
                            <!-- ShipStation orders do note have details visible on a form -->
                            <a *ngIf="element.orderSource != 'Manually Entered'" 
                                target="_blank"
                                title="{{element.genericId}}" 
                                class="shipstation-id"
                                [routerLink]="'../../orders-outbound/ss/' + element.genericId" >
                                Simple WMS Link (Client's reference)
                            </a>
                        </li>
                    </ul>


                    <h3> Print Labels</h3>
                    <div>
                        <ul>

                            <!-- Simple WMS Orders CLIENT ATTACHED LABEL -->
                            <li *ngFor="let document of element.associatedDocuments">
                                <!-- TODO: need file name -->
                                <a href="javascript:void()" role="button" (click)="downloadFile(document)">
                                    Attachment to download
                                </a>
                            </li>

                            
                            <!-- Simple WMS Orders WE PRINT LABEL in ShipStation -->
                            <li *ngIf="element.associatedDocuments?.length < 1 || element.orderSource != 'Manually Entered'">
                                <!-- TODO: future cases less simple-->
                                <a
                                    target="_blank"
                                     href="https://ship13.shipstation.com/orders/all-orders-search-result?quickSearch={{element.name}}">
                                    Print in ShipStation
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        </ng-container>
    
        <!-- Order number Column -->
        <ng-container matColumnDef="name">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Order # </th>
        <td mat-cell *matCellDef="let element" class="we-name-col"> 
            <!-- Simple WMS orders have links to readonly forms -->
            <a *ngIf="element.orderSource == 'Manually Entered'" 
                target="_blank"
                [routerLink]="['../../orders-outbound/' + element.genericId]">
                {{element.name}} 
            </a>

            <!-- ShipStation orders link to SS order details page -->
            <a *ngIf="element.orderSource != 'Manually Entered'"
                target="_blank"
                class="shipstation-id" 
                [routerLink]="'../../orders-outbound/ss/' + element.genericId">
                {{element.name}}
            </a>
        </td>
        </ng-container>

        <!-- Order source Column -->
        <ng-container matColumnDef="orderSource">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Order Source </th>
        <td mat-cell *matCellDef="let element"> 
            <span class="client-name">
                {{element.associated3plClientName}}
            </span>
            {{element.orderSource}}
        </td>
        </ng-container>
    
        <!-- DateRequested Column -->
        <ng-container matColumnDef="dateRequested">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Order Requested Date </th>
        <td mat-cell *matCellDef="let element"> {{element.dateRequested | date: 'mediumDate'}} </td>
        </ng-container>

        
        <!-- items orders Column -->
        <ng-container matColumnDef="itemsOrdered">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Total SKUs </th>
        <td mat-cell *matCellDef="let element"> {{element.numberOfLineItemsOrdered}} </td>
        </ng-container>

        <!-- number of items sold on orders Column -->
        <ng-container matColumnDef="totalUnits">
        <th mat-header-cell  mat-sort-header *matHeaderCellDef> Total Units </th>
        <td mat-cell *matCellDef="let element"> {{element.totalUnits}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="250"
                    class="pager-container"
                    showFirstLastButtons="true"
                    [pageSizeOptions]="[10, 25, 100, 250, 500]">
    </mat-paginator>
</div>




<h2>ON HOLD</h2>
TODO: collapsed and then table that loads when expand

<h2>Shipped & Issued Orders</h2>
TODO: collapsed and then table that loads when expand

<h2>Cancelled Orders</h2>
TODO: collapsed and then table that loads when expand