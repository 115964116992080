import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class OpenboxesProductsService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'OpenBoxesProducts/';
   }

  getAllProducts(): Observable<any> {
    return this.http.get(this.API_URL 
    , { responseType: 'json' });
  }

  /*
  getById(id: string): Observable<any> {
    return this.http.get(this.API_URL + id
    , { responseType: 'json' });
  }

  create(product: Product) {
    return this.http.post(this.API_URL, product);
  }

  update(id: string, product: Product) {
    return this.http.put(this.API_URL + id, product);
  }
  */
}
