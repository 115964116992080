import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { SpinnerOverlayService } from "../lib/is-loading/spinner-overlay.service";

@Injectable()
export class IsLoadingInterceptor implements HttpInterceptor {
    constructor(public spinnerOverlayService: SpinnerOverlayService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //return next.handle(req);
        this.spinnerOverlayService.show(req.urlWithParams);
        return next.handle(req).pipe(
            finalize(() => this.spinnerOverlayService.hide(req.urlWithParams))
        );
    }
}