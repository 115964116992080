<h1>Products</h1>

<button class="btn btn-secondary" (click)="goToAddProduct()">
  Add Product
</button>
<button class="btn btn-secondary left-padded" routerLink="import">
  Import from Excel
</button>
<br/><br/>

<mat-form-field>
    <mat-label class="filter-text">Filter: </mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Enter search keywords" #input class="filterInput">
</mat-form-field>
  


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-wrapper">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Product </th>
      <td mat-cell *matCellDef="let element"> 
          <a [routerLink]="[element.id]">{{element.name}} </a>
        </td>
    </ng-container>
  
    <!-- Barcode Column -->
    <ng-container matColumnDef="barcode">
      <th mat-header-cell *matHeaderCellDef> Barcode </th>
      <td mat-cell *matCellDef="let element"> {{element.barcode}} </td>
    </ng-container>
  
    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>