<h1>Warehouse Manager Dashboard</h1>

<div class="table">
    <div class="row">
        <div class="col-md-4">
            <h2>OpenBoxes Sync to Simple WMS</h2>
            <a (click)="runEndOfDayJob()"  class="btn btn-primary" >
                <span *ngIf="endOfDayLoading" class="spinner-border spinner-border-sm mr-1"></span>
                Run End of Day Job
            </a>
            <br/>
            <br/>
            <a (click)="runProductSync()" class="btn btn-primary">Sync Products to Simple WMS</a>
        </div>
        
        <div class="col-md-4">
            &nbsp;
        </div>

        <div class="col-md-4">
            &nbsp;
        </div>
    </div>
</div>