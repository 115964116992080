import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class WarehouseEndOfDayService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'WmsManagerEndOfDay/';
   }

  runEndOfDayJob(requestObj: any) {
    return this.http.post(this.API_URL, requestObj);
  }
}
