import { Component, OnInit } from '@angular/core';


// TODO: unused file?
@Component({
  selector: 'app-spinner-overlay-wrapper',
  templateUrl: './spinner-overlay-wrapper.component.html',
  styleUrls: ['./spinner-overlay-wrapper.component.css']
})
export class SpinnerOverlayWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
