import { Component, OnInit } from '@angular/core';
import { PaginatedResult } from './models/paginatedResult';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Product } from './models/product';
import { ProductsService } from './products.service';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { Environment } from 'ag-grid-community';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  productsOnThisPage: Product[];
  displayedColumns: string[] = ['name', 'barcode', 'description'];
  dataSource:any;

  constructor(private productsService: ProductsService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit(): void {
    this.getProducts();
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToAddProduct(){
    // go to edit page without an ID to trigger Add
    this.router.navigate(['/products', '']);
  }

  private getProducts(){
    // TODO: finish this tutorial to get paging setup https://blog.angular-university.io/angular-material-data-table/
    this.productsService.getAllProducts()
      .subscribe(
        result =>
        {
          this.productsOnThisPage = result.data;          
          this.dataSource = new MatTableDataSource(this.productsOnThisPage);
        },
        error =>
        {
          this.alertService.error(
            'Unexpected error getting products. message:<br/><br/>' 
          + JSON.stringify(error));
        }
      )
  }
}
