import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TokenStorageService } from './_services/authentication-management/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Simple WMS';
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;

  constructor(private tokenStorageService: TokenStorageService
    , private router: Router) {
      this.router.events
        .pipe(
          filter( event =>event instanceof NavigationStart)
        )
        .subscribe((event: NavigationStart)=>{
          //(event: NavigationEvent) => {
            console.log('nav event:', event);
            var isLoggedIn = this.tokenStorageService.isLoggedIn();

            // TODO: need to find a way to allow annoynmous access across the app
            //       and handle View vs edit vs Admin permissions, etc
            var isLoginPage = event.url.toLowerCase().indexOf('/login') > -1;

            if (!isLoggedIn && !isLoginPage) {
              this.redirectToLogin();
            }
        }
      )

      // this.router.events.subscribe((event: Event) => {
      //   if (!!this.tokenStorageService.getToken()) {
      //     this.redirectToLogin();
      //   }
      // })
    }

  
  ngOnInit(): void {
    this.isLoggedIn = this.tokenStorageService.isLoggedIn();
    console.log('this.isLoggedIn', this.isLoggedIn);

    if (this.isLoggedIn) {
      // Seed the roles for a user when they log-in
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      // TODO: unused vars?   rk 2021.09.10
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
    }else{
      this.redirectToLogin();
    }
  }

  private redirectToLogin() {
      this.router.navigateByUrl('/login');
  }
}
