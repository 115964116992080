import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerOverlayWrapperComponent } from './spinner-overlay-wrapper/spinner-overlay-wrapper.component';
import { SpinnerWrapperComponent } from './spinner-wrapper/spinner-wrapper.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

// contents of this lib from tutorial https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/

@NgModule({
  declarations: [SpinnerComponent, SpinnerOverlayWrapperComponent, SpinnerWrapperComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class IsLoadingModule { }
