<h1>Inventory</h1>

<div class="table">
    <div class="row">
        <span class="col-2 field-label">Last Updated:</span>
        <span class="col-4">{{lastUpdated | date: 'medium'}} EST</span>
        <span class="col-2">
          <button (click)="downloadInventory()"
             class="btn btn-secondary">
            Export All
          </button>
        </span>
    </div>
    <div class="row">
        <span class="col-2 field-label">Total SKUs:</span>
        <span class="col-4">{{inventory?.length}}</span>
    </div>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-wrapper">

    <!-- Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> 
          <span>{{element.name}} </span>
        </td>
    </ng-container>
  
    <!-- SKU Column -->
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef> SKU </th>
      <td mat-cell *matCellDef="let element"> 
        <span>{{element.sku}} </span>
      </td>
    </ng-container>

    <!-- Quantity On hand Column -->
    <ng-container matColumnDef="quantityOnHand">
      <th mat-header-cell *matHeaderCellDef> Quanity On Hand </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.quantityOnHand}}
      </td>
    </ng-container>
  
    <!-- Available To Promise Column -->
    <ng-container matColumnDef="quantityAvailableToPromise">
      <th mat-header-cell *matHeaderCellDef> Qty Available to Promise </th>
      <td mat-cell *matCellDef="let element">
         {{element.quantityAvailableToPromise}} 
          <span *ngIf="element.needsReordered" class="reorder-tooltip"
            title="Quantity Available is less than Reorder Point. Reorder soon.">
            &nbsp;&nbsp;
            <i class="fa fa-battery-quarter"></i>
            REORDER THIS PRODUCT
          </span>
        </td>
    </ng-container>

    <!-- Reorder Point Column -->
    <ng-container matColumnDef="reorderPoint">
      <th mat-header-cell *matHeaderCellDef> Reorder Point (if set) </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.reorderPoint}}
      </td>
    </ng-container>
  

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
