<h1 class="header" *ngIf="isCreateMode">Create Recipient: <i>{{ag.displayName.value}}</i></h1>
<h1 class="header" *ngIf="!isCreateMode">Edit Recipient: <i>{{ag.displayName.value}}</i></h1>

<form [formGroup]="addressGroup" (ngSubmit)="onAddressGroupSubmit()">
    <h2>Recipient Info</h2>
    <div class="form-row">
        <div class="form-group col">
            <label>Display Name</label>
            <input type="text" formControlName="displayName" class="form-control" [ngClass]="{ 'is-invalid': submitted && ag.displayName.errors }" />
            <div *ngIf="submitted && ag.displayName.errors" class="invalid-feedback">
                <div *ngIf="ag.displayName.errors.required">Display name is required</div>
            </div>
        </div>
    </div> 
    
    <div class="form-row">
        <div class="form-group col">
            <label>
                <popper-content #popper1Content>
                    <div class="active-tooltip">
                        Active recipients can have outbound orders shipped to them. Inactive recipients cannot be used.
                    </div>
                  </popper-content>
                  <div [popper]="popper1Content"
                        [popperShowOnStart]="false"
                        [popperTrigger]="'hover'"
                        [popperHideOnClickOutside]="true"
                        [popperHideOnScroll]="true"
                        [popperPlacement]="'bottom'">          
                      <p class="pointer" style="font-weight: bold;">
                        <i class="fa fa-info-circle pointer"></i>&nbsp;&nbsp;Is Active
                      </p>    
                  </div>
                <input type="checkbox" formControlName="isActive" class="form-control" />
            </label>
        </div>
    </div>   

    <h2>Ship To Address</h2>
    <div class="form-row">
        <div class="form-group col">
            <label>Recipient</label>
            <input type="text" formControlName="recipient1" class="form-control" [ngClass]="{ 'is-invalid': submitted && ag.recipient1.errors }" />
            <div *ngIf="submitted && ag.recipient1.errors" class="invalid-feedback">
                <div *ngIf="ag.recipient1.errors.required">Recipient is required</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label>Company (optional)</label>
            <input type="text" formControlName="recipient2" class="form-control" />
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label>Address</label>
            <input type="text" formControlName="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && ag.address1.errors }" />
            <div *ngIf="submitted && ag.address1.errors" class="invalid-feedback">
                <div *ngIf="ag.address1.errors.required">Address is required</div>
            </div>
        </div>
    </div>
    <div class="form-row" >
        <div class="form-group col">
            <label>Address Line 2 (optional)</label>
            <input type="text" formControlName="address2" class="form-control"  />
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label>Address line 3 (optional)</label>
            <input type="text" formControlName="address3" class="form-control"  />
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-4">
            <label>City</label>
            <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && ag.city.errors }" />
            <div *ngIf="submitted && ag.city.errors" class="invalid-feedback">
                <div *ngIf="ag.city.errors.required">City is required</div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>State</label>

            <ng-select ngSelectMat [items]="allStates"
                                    bindLabel="displayName"
                                    placeholder="Selected state"
                                    formControlName="state"    
                                    class="stateDropdown"
                                    [ngClass]="{ 'is-invalid': submitted && ag.state.errors }"
                                    required="true" >
                    <!--{{item?.displayName}}-->
            </ng-select>

            <div *ngIf="submitted && ag.state.errors" class="invalid-feedback">      
                <div *ngIf="ag.state.errors.required">State is required</div>
            </div>
        </div>
        <div class="form-group col-md-2">
            <label>Zip Code</label>
            <input formControlName="zipCode" class="form-control" 
            [ngClass]="{ 'is-invalid': submitted && ag.zipCode.errors }" />
            <div *ngIf="submitted && ag.zipCode.errors" class="invalid-feedback">      
                <div *ngIf="ag.zipCode.errors.required || ag.zipCode.invalid">Zip code is required in 5 or 9 character format (i.e. 12345 or 12345-0987)</div>
            </div>
        </div>
    </div>     
    
    <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Submit
    </button>
    
    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
</form> 