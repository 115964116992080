import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'roles/';
   }
  getAllRoles(): Observable<any> {
    return this.http.get(this.API_URL
    , { responseType: 'json' });
  }
}
