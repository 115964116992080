
<h1 class="order-header" *ngIf="isCreateMode">Create Order Details: <i>{{odg.name.value}}</i></h1>
<h1 class="order-header" *ngIf="!isCreateMode">View Order Details: <i>{{odg.name.value}}</i></h1>
<div class="order-status-wrapper">
    <div class="row">
        <div class="col-md-6">
            <span>Order Status</span>
            <span class="orderStatusValue">{{odg.orderStatus.value}}</span>
        </div>        

        <div class="col-md-6" *ngIf="shipmentDetails != null && shipmentDetails.length > 0">
            <div>
                <a (click)="goToShipmentDetails()" 
                    title="Go to Shipment Details." 
                    class="btn btn-secondary">View shipment details</a>
            </div>
        </div>
    </div>
</div>

    <mat-horizontal-stepper [linear]="orderDetailsGroup.invalid || lineDetailsGroup.invalid || ShippingAndDocsGroup.invalid" #stepper>
        <ng-template matStepperIcon="edit">
            <mat-icon>check_circle_outline</mat-icon>
        </ng-template>
        
        <mat-step [stepControl]="orderDetailsGroup" label="Order Details">
            
            <form [formGroup]="orderDetailsGroup" (ngSubmit)="onOrderDetailsSubmit()">

                <div class="form-row">
                    <div class="form-group col">
                        <label>Order Number</label>
                        <input type="text" placeholder="PO-####" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': orderDetailsSubmitted && odg.name.errors }" />
                        <div *ngIf="orderDetailsSubmitted && odg.name.errors" class="invalid-feedback">
                            <div *ngIf="odg.name.errors.required">Order Number is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label>Date Order Placed</label>
                        <input formControlName="dateRequested" class="form-control eighty-percent-width" readonly />
                    </div>
                    <div class="form-group col-md-4">
                        <label class="display-block">Created By</label>
                        <input formControlName="createdByFullName" class="form-control eighty-percent-width" readonly />
                    </div>
                </div>    
                
                
            <h2>Recipient Details</h2>
            
            <div class="form-row">
                <div class="form-group col-4">
                        
                    <popper-content #popperContentHasRecipient>
                        Untick this box if you are providing the shipping documents (label or BOL) for your shipment. The recipient’s address details are only required when Warehousing Etc is providing the transportation for the shipment. 
                    </popper-content>
                    <div [popper]="popperContentHasRecipient"
                            [popperShowOnStart]="false"
                            [popperTrigger]="'click'"
                            [popperHideOnClickOutside]="true"
                            [popperHideOnScroll]="true"
                            [popperPlacement]="'bottom'">          
                        
                        <label class="pointer">
                            <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Has Recipient
                        </label>
                    </div>
                    <label class="clickable-label-recipient">
                        <input type="checkbox" formControlName="hasRecipient" class="form-control recipient-checkbox" (change)="onHasRecipientChange($event)" />
                        <span class="normal-font" *ngIf="odg.hasRecipient.value">
                            <i>UNTICK if NO Recipient Address to enter</i>
                        </span>
                        <span class="normal-font" *ngIf="!odg.hasRecipient.value">
                            <i>TICK to enter Recipient Address</i>
                        </span>
                    </label>
                </div>
                <div class="form-group col-4">
                    <div *ngIf="odg.hasRecipient.value && allSavedRecipientAddresses?.length > 0">
                        <label>Use a Saved Recipient</label>                    
                        <ng-select ngSelectMat [items]="allSavedRecipientAddresses"
                                                formControlName="selectedSavedRecipient"
                                                bindLabel="displayName"
                                                placeholder="Select a recipient address"   
                                                class="savedRecipientDropdown"
                                                [readonly]="!isCreateMode">
                        </ng-select>
                    </div>
                </div>
                <div class="col-4">
                    &nbsp;
                </div>
            </div>


            <div class="form-row" *ngIf="odg.hasRecipient.value">
                <div class="form-group col">
                    <label>Recipient</label>
                    <input type="text" formControlName="recipient1" class="form-control" [ngClass]="{ 'is-invalid': orderDetailsSubmitted && odg.recipient1.errors }" />
                    <div *ngIf="orderDetailsSubmitted && odg.recipient1.errors" class="invalid-feedback">
                        <div *ngIf="odg.recipient1.errors.required">Recipient is required</div>
                    </div>
                </div>
            </div>
            <div class="form-row" *ngIf="odg.hasRecipient.value">
                <div class="form-group col">
                    <label>Company (optional)</label>
                    <input type="text" formControlName="recipient2" class="form-control" />
                </div>
            </div>
            <div class="form-row" *ngIf="odg.hasRecipient.value">
                <div class="form-group col">
                    <label>Address</label>
                    <input type="text" formControlName="address1" class="form-control" [ngClass]="{ 'is-invalid': orderDetailsSubmitted && odg.address1.errors }" />
                    <div *ngIf="orderDetailsSubmitted && odg.address1.errors" class="invalid-feedback">
                        <div *ngIf="odg.address1.errors.required">Address is required</div>
                    </div>
                </div>
            </div>
            <div class="form-row"  *ngIf="odg.hasRecipient.value">
                <div class="form-group col">
                    <label>Address Line 2 (optional)</label>
                    <input type="text" formControlName="address2" class="form-control"  />
                </div>
            </div>
            <div class="form-row" *ngIf="odg.hasRecipient.value">
                <div class="form-group col">
                    <label>Address line 3 (optional)</label>
                    <input type="text" formControlName="address3" class="form-control"  />
                </div>
            </div>
            <div class="form-row" *ngIf="odg.hasRecipient.value">
                <div class="form-group col-md-4">
                    <label>City</label>
                    <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': orderDetailsSubmitted && odg.city.errors }" />
                    <div *ngIf="orderDetailsSubmitted && odg.city.errors" class="invalid-feedback">
                        <div *ngIf="odg.city.errors.required">City is required</div>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>State</label>

                    <ng-select ngSelectMat [items]="allStates"
                                            bindLabel="displayName"
                                            placeholder="Selected state"
                                            formControlName="state"    
                                            class="stateDropdown"
                                            [readonly]="!isCreateMode"
                                            [ngClass]="{ 'is-invalid': orderDetailsSubmitted && odg.state.errors }"
                                            required="true" >
                            {{item?.displayName}}
                    </ng-select>

                    <div *ngIf="orderDetailsSubmitted && odg.state.errors" class="invalid-feedback">      
                        <div *ngIf="odg.state.errors.required">State is required</div>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label>Zip Code</label>
                    <input formControlName="zipCode" class="form-control" 
                    [ngClass]="{ 'is-invalid': orderDetailsSubmitted && odg.zipCode.errors }" />
                    <div *ngIf="orderDetailsSubmitted && odg.zipCode.errors" class="invalid-feedback">      
                        <div *ngIf="odg.zipCode.errors.required || odg.zipCode.invalid">Zip code is required in 5 or 9 character format (i.e. 12345 or 12345-0987)</div>
                    </div>
                </div>
            </div>
                <!-- NOTE: Keep this inside the form to validate fields before NEXT -->
                <div>
                    <button matStepperNext id="btnOrderDetailsNext" class="btn btn-primary">Next</button>
                    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
                </div>           
            </form> 
        </mat-step>

        
        <mat-step [stepControl]="lineDetailsGroup" label="Line Details">
            
            <form [formGroup]="lineDetailsGroup" (ngSubmit)="onLineDetailsSubmit()">
                <h2>Items</h2>

                <div>
                        <div formArrayName="lineItems" class="line-items-container">
                            <div class="form-row" 
                                *ngFor="let item of lineItems.controls; let lineIndex=index" 
                                [formGroupName]="lineIndex">
                                <div class="col-md-6">
                                    <label class="display-block">Line #{{(lineIndex + 1)}}</label>     


                                    <mat-form-field class="full-width product-list">
                                        <ng-select ngSelectMat [items]="openboxesProducts"
                                            bindLabel="name"
                                            placeholder="Selected product"
                                            formControlName="product"    
                                            [searchFn]="productSearch"
                                            [readonly]="!isCreateMode"
                                            required="true" >

                                            <!-- VIEW template -->
                                            <ng-template ng-label-tmp let-item="item" let-index="index">
                                                <ng-container>
                                                <b>({{item.product_code}})</b> {{item.name}}
                                                </ng-container>
                                            </ng-template>

                                            <!-- EDIT template -->
                                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                                <ng-container>
                                                <b>({{item.product_code}})</b> {{item.name}}
                                                </ng-container>
                                            </ng-template>
                                        </ng-select>

                                    </mat-form-field>

                                    <br/>
                                </div>
                                
                                <div class="col-md-1">
                                    <label>Quantity</label>
                                    
                                    <mat-form-field class="full-width">
                                        <input type="number" min="0"  
                                                matInput     
                                                autocomplete="off"  
                                                [readonly]="!isCreateMode"
                                                formControlName="quantityOrdered" 
                                                class="form-control"
                                                required="true" />
                                    </mat-form-field>
                                </div>

                                <div class="col-md-1 moveButtonDown">
                                    <i class="fa fa-times red-text delete-button pointer" 
                                            *ngIf="isCreateMode && lineIndex !== 0"
                                        (click)="deleteLineItem(lineIndex)" title="Delete item"></i>
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary"
                                    *ngIf="isCreateMode"
                                    (click)="addLineItem()"><i class="fa fa-plus"></i>&nbsp;&nbsp;Add Item</button>
                    </div>

                </div>
                <br/>
                <br/>   
                <!-- NOTE: Keep this inside the form to validate fields before NEXT -->                
                <div>
                    <button matStepperPrevious class="btn btn-secondary">Back</button>
                    <button matStepperNext class="btn btn-primary add-15px-margin-left">Next</button>
                    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
                </div>
            </form>

    </mat-step>


    <mat-step [stepControl]="ShippingAndDocsGroup" label="Shipping & Docs">   
            
        <form [formGroup]="ShippingAndDocsGroup" (ngSubmit)="onSubmit()">

            <h2>Shipping Label</h2>
            <div class="form-row">
                <div class="table">
                    <div class="form-row">
                        <div class="col-md-6">
                            <label>Will you create the shipping label?</label>
                            
                            <select formControlName="clientGeneratesShippingLabel"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && sdg.clientGeneratesShippingLabel.errors }">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <br />

                            
                            <label [hidden]="sdg.clientGeneratesShippingLabel.value == 'yes'">What shipping method do you want to use?</label>
                            <select formControlName="weShippingMethod"
                                class="form-control"
                                [hidden]="sdg.clientGeneratesShippingLabel.value == 'yes'">
                                <option value="leastCost">Least Cost Method</option>
                                <option value="1day">Next Day</option>
                                <option value="2day">2 Day</option>
                                <option value="3day">3 Day</option>
                                <option value="standardParcel">Standard Parcel</option>
                                <option value="ltl">LTL - Less than Truck Load</option>
                                <option value="dedicatedCarrier">Dedicated Carrier - Full Truck</option>
                            </select>
                            <br />



                            <label [hidden]="sdg.clientGeneratesShippingLabel.value != 'yes'">Do YOU have the shipping label(s) to attach NOW?</label>
                            <select formControlName="attachLabelNow"
                                class="form-control"
                                [hidden]="sdg.clientGeneratesShippingLabel.value != 'yes'">
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <br />



                            <span class="manual-label-create-instructions" 
                                    [hidden]="sdg.clientGeneratesShippingLabel.value == 'no' || sdg.attachLabelNow.value == 'yes'">
                                We will send you an email with weights and dimension of the parcel. Then you can create the shipping label and email it to the shipping department.
                            </span>

                            <app-file-upload (onUploadFinished)="uploadShippingLabelFinished($event)"
                                    [files]="shippingLabelsFiles"
                                    [readonly]="!isCreateMode"
                                    [attachMultipleFiles]="true"
                                    [hidden]="sdg.clientGeneratesShippingLabel.value != 'yes' || sdg.attachLabelNow.value != 'yes'"
                                    [fileType]="shippingLabelFileType"></app-file-upload>
                        
                        

                                <label>Do you have other documents to upload?</label>
                                
                                <select formControlName="clientHasOtherDocumentsToUpload"
                                        class="form-control" [ngClass]="{ 'is-invalid': submitted && sdg.clientHasOtherDocumentsToUpload.errors }">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <br/>

                                
                                <label [hidden]="sdg.clientHasOtherDocumentsToUpload.value != 'yes'">Attach Other Files</label>
                                
                                <app-file-upload (onUploadFinished)="uploadOtherAttachmentsFinished($event)"
                                        [files]="otherAttachmentsFiles"
                                        [readonly]="!isCreateMode"
                                        [attachMultipleFiles]="true"
                                        [hidden]="sdg.clientHasOtherDocumentsToUpload.value != 'yes'"
                                        [fileType]="otherAttachmentsFileType"></app-file-upload>
                                
                        </div>
                        
                        <div class="col-md-6 shipment-details-wrapper">
                            <div *ngIf="shipmentDetails != null && shipmentDetails.length > 0">
                                <span>
                                    <b>Total Shipments:</b> &nbsp; 
                                    <span class="number-shipments">{{shipmentDetails.length}}</span>
                                </span>


                                <div class="table shipments-table"
                                    *ngFor="let shipment of shipmentDetails">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <span *ngIf="shipment.voided" 
                                                class="voided-identifier"
                                                title="This tracking number was voided.">
                                                <i class="fa fa-ban blue-text">VOIDED</i>                                                
                                            </span>
                                        </div>
                                        <div class="col-md-5">
                                            {{shipment.serviceCode}} <i>via</i>
                                            {{shipment.carrierCode}}
                                        </div>
                                        <div class="col-md-5">
                                            {{shipment.trackingNumber}}
                                        </div>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col">
                    <label>Notes:</label><br/>
                    <textarea formControlName="notes">

                    </textarea>
                </div>  
            </div>

            <div class="form-row">
                <div class="form-group">
                    <button matStepperPrevious class="btn btn-secondary">Back</button>
                    <button *ngIf="isCreateMode" [disabled]="loading" class="btn btn-primary add-15px-margin-left">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit Order
                    </button>
                    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
                </div>
            </div>
        </form>
    </mat-step>
</mat-horizontal-stepper>