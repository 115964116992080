import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {FlexLayoutModule} from "@angular/flex-layout";

import { MatSliderModule } from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar'; 
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { DndModule } from 'ngx-drag-drop';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { WmsNavComponent } from './components/wms-nav/wms-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { UserComponent } from './features/users/user/user.component';
import { WeBoardsComponent } from './lib/we-boards/we-boards.component';
import { WeCardComponent } from './lib/we-boards/we-card/we-card.component';
import { WeListComponent } from './lib/we-boards/we-list/we-list.component';
import { WeFormBuilderComponent } from './lib/we-form-builder/we-form-builder.component';
import { FormBuilderAdminComponent } from './features/form-builder-admin/form-builder-admin.component';
import { BoardsDemoComponent } from './features/boards-demo/boards-demo.component';
import { ProductsComponent } from './features/products/products.component';
import { InventoryComponent } from './features/inventory/inventory.component';
import { AnalyticsComponent } from './features/analytics/analytics.component';
import { RegisterComponent } from './features/register/register.component';
import { ProfileComponent } from './features/profile/profile.component';
import { BoardAdminComponent } from './features/board-admin/board-admin.component';
import { BoardModeratorComponent } from './features/board-moderator/board-moderator.component';
import { LoginComponent } from './features/login/login.component';
import { GlobalConfigService } from './_services/global-config.service';
import { AuthInterceptor } from './_helpers/auth.interceptor';


import { NgSelectModule } from '@ng-select/ng-select';

import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ProductComponent } from './features/products/product/product/product.component';
import { AlertModule } from './lib/alerts/alert.module';
import { ImportSheetComponent } from './lib/import-sheet/import-sheet.component';
import { ImportProductsComponent } from './features/products/import-products/import-products.component';
import { ClientDashboardComponent } from './features/client-dashboard/client-dashboard.component';
import { OrdersOutboundComponent } from './features/orders-outbound/orders-outbound.component';
import { OutboundOrderComponent } from './features/orders-outbound/outbound-order/outbound-order.component'
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule} from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileUploadComponent } from './features/file-upload/file-upload.component';
import { GlobalHttpAuthErrorInterceptorService } from './_helpers/globalHttpAuthError.interceptor';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgSelectFormFieldControlDirective } from './_helpers/ng-mat-extensions/ng-select.directive';
import { IsLoadingModule } from './lib/is-loading/is-loading.module';
import { IsLoadingInterceptor } from './_helpers/isLoading.interceptor';
import { UsersComponent } from './features/users/users.component';
import { AssociatedClient3plComponent } from './features/associated-client3pls/associated-client3pl/associated-client3pl.component';
import { AssociatedClient3plsComponent } from './features/associated-client3pls/associated-client3pls.component';
import { ChangePasswordComponent } from './features/users/user/change-password/change-password.component';
import { MyProfileComponent } from './features/users/user/my-profile/my-profile.component';
import { WarehouseManagerComponent } from './features/warehouse-manager/warehouse-manager.component';
import { SavedRecipientsComponent } from './features/orders-outbound/saved-recipients/saved-recipients.component';
import { SavedRecipientComponent } from './features/orders-outbound/saved-recipients/saved-recipient/saved-recipient.component';
import { SsOrderComponent } from './features/orders-outbound/outbound-order/ss-order/ss-order.component';
import { WarehouseOutboundsComponent } from './features/warehouse-outbounds/warehouse-outbounds.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    WmsNavComponent,
    UserComponent,
    WeBoardsComponent,
    WeCardComponent,
    WeListComponent,
    WeFormBuilderComponent,
    FormBuilderAdminComponent,
    BoardsDemoComponent,
    ProductsComponent,
    InventoryComponent,
    AnalyticsComponent,
    RegisterComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    ProductComponent,
    ImportSheetComponent,
    ImportProductsComponent,
    ClientDashboardComponent,
    OrdersOutboundComponent,
    OutboundOrderComponent,    
    SsOrderComponent,
    FileUploadComponent,

    NgSelectFormFieldControlDirective,

    UsersComponent,
    UserComponent,
    AssociatedClient3plComponent,
    AssociatedClient3plsComponent,
    ChangePasswordComponent,
    MyProfileComponent,
    WarehouseManagerComponent,
    SavedRecipientsComponent,
    SavedRecipientComponent,
    WarehouseOutboundsComponent
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    AgGridModule.withComponents([]),
    NgxPopperjsModule.forRoot(),

    BrowserModule,
    DndModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    
    NgSelectModule,
    
    MatSliderModule,
    MatStepperModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatSelectModule,
    MatFormFieldModule,    
    MatInputModule,

    RouterModule,
    AlertModule,
    IsLoadingModule,

    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatButtonModule,
    MatIconModule,
    MatListModule
  ],
  providers: 
  [
    GlobalConfigService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: GlobalHttpAuthErrorInterceptorService,
      multi   : true,
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: IsLoadingInterceptor,
      multi   : true,
    }
  ],
  bootstrap: [AppComponent],  
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
