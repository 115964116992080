import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfigService } from 'src/app/_services/global-config.service';
import { OrderShippedDetails } from '../models/order-shipped-details';

@Injectable({
  providedIn: 'root'
})
export class OrderShippedDetailsService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'OrdersOutboundShippingDetails/';
   }

  update(id: string, shippedDetails: OrderShippedDetails) {
    return this.http.put(this.API_URL + id, shippedDetails);
  }
}
