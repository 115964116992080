import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { AssociatedClient3plsService } from '../associated-client3pls.service';

@Component({
  selector: 'app-associated-client3pl',
  templateUrl: './associated-client3pl.component.html',
  styleUrls: ['./associated-client3pl.component.css']
})
export class AssociatedClient3plComponent implements OnInit {
  id: string;
  isCreateMode: boolean;
  clientGroup: FormGroup;
  clientSubmitted = false;
  loading = false;

  constructor(    
    private client3plService: AssociatedClient3plsService,
    private userRoleService: UserRoleService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.userRoleService.RedirectIfNotAdminUser();
    this.id = this.route.snapshot.params['id'];
    this.isCreateMode = !this.id;

    this.clientGroup = this.formBuilder.group({
      simpleWms3plConfigId: [''],
      name: ['', Validators.required],
      canAccessPortal: [true],
      openBoxesId: ['', Validators.required],
      productTagId: ['', Validators.required],
      defaultOpenBoxesParcelOriginId: ['', Validators.required],
      defaultOpenBoxesParcelDestinationId: ['', Validators.required],
      manualOrdersEnabled: [false],
      shipStationEnabled: [false],
      shipStatonSimpleIntegrationStoreId: [''],

    }, {
        validator: null
    });

    if(!this.isCreateMode){
      this.loadPageData();
    }
  }

  private loadPageData(): void{    
    this.client3plService.getById(this.id)
    .pipe(first())
    .subscribe(x => {            
            this.clientGroup.patchValue(x.data);
    });
  }

  get cg() { return this.clientGroup.controls; }

  onClientGroupSubmit(){
    this.clientSubmitted = true;    

    if(this.clientGroup.invalid){
        return;
    }

    if(this.isCreateMode){
      this.clientGroup.controls['simpleWms3plConfigId'].setValue(null);
      this.createClient(this.clientGroup.value);
    }
    else{
      this.updateClient(this.clientGroup.value);
    }
  }  

  private createClient(newClient: any): void{
    this.loading = true;

    this.client3plService.create(newClient)    
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Client added', { keepAfterRouteChange: true });
                this.router.navigate(['../'], { relativeTo: this.route });
                this. loading = false;
            },
            error: error => {
                this.alertService.error('Unexpected error, your client may have been partially created or not created at all. Check all client. Contact support for help.  Details: ' + error);
            }
        });
  }
  
  private updateClient(newClient: any): void{
    this.client3plService.update(this.id, newClient)    
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Client Updated', { keepAfterRouteChange: true });
                this.router.navigate(['../'], { relativeTo: this.route });
                this. loading = false;
            },
            error: error => {
              this.loading = false;
                this.alertService.error('Unexpected error, your client may have been partially updated or all changes lost. Retry or contact support for help.  Details: ' + error);
                //this.router.navigate(['../'], { relativeTo: this.route });
            }
        });
  }
  
  cancel(){
    if(this.isCreateMode){
        if(confirm('Are you sure you want to discard all your changes?')){
            this.router.navigate(['/clients']);
        }
    }else {
        this.router.navigate(['/clients']);
    }
  }
}
