import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfigService } from 'src/app/_services/global-config.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {  
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'changePassword/';
   }

  changePassword(id: string, passworChangedRequest: any) {
    return this.http.put(this.API_URL + id, passworChangedRequest);
  }
}
