import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalConfigService } from 'src/app/_services/global-config.service';
import { OrdersOutbound } from './models/ordersOutbound';

@Injectable({
  providedIn: 'root'
})
export class OutboundOrdersService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'OrdersOutbound/';
   }

   getById(id: string): Observable<any> {
    return this.http.get(this.API_URL + id
    , { responseType: 'json' });
  }
   
  getAllOutboundOrders(pageNumber: number = 1, pageSize: number = 25): Observable<any> {
    return this.http.get(this.API_URL + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize
    , { responseType: 'json' });
  }

  findOrders(
    filterSearchText = ''
    , sortField: string, sortOrder: string
    ,pageNumber: number, pageSize: number
    , filterStatuses = []
    , dateRequestedStart = null
    , dateRequestedEnd = null
    , filterOrderSource = null):  Observable<any> {

    return this.http.get(this.API_URL, {
        params: new HttpParams()
            .set('filterSearchText', filterSearchText)
            .set('sortField', sortField)
            .set('sortOrder', sortOrder)
            .set('pageNumber', pageNumber.toString())
            .set('pageSize', pageSize.toString())
            .set('filterStatuses', filterStatuses.toString())
            .set('dateRequestedStart', dateRequestedStart)
            .set('dateRequestedEnd', dateRequestedEnd)
            .set('filterOrderSource', filterOrderSource)
    }).pipe(
      // return the whole payload. we care about res["data"] as the list of all and res["totalCount"] which is all possible results in pager
        map(res =>  res)
    );
}

  create(OutboundOrder: OrdersOutbound) {
    return this.http.post(this.API_URL, OutboundOrder);
  }

  update(id: string, OutboundOrder: OrdersOutbound) {
    return this.http.put(this.API_URL + id, OutboundOrder);
  }
}
