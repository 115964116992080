import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { UsersService } from '../../users.service';
import { MyProfileService } from './my-profile.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  id: string;
  userGroup: FormGroup;
  userSubmitted = false;
  loading = false;

  constructor(    
    private usersService: UsersService,
    private myProfileService: MyProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private userRoleService: UserRoleService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.userRoleService.RedirectIfNotCurrentUserAccess(this.id);

    this.userGroup = this.formBuilder.group({
      id: [''],
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['']
    }, {
        validator: null
    });

    this.loadPageData();
  }

  private loadPageData(): void{        
      this.usersService.getById(this.id)
        .pipe(first())
        .subscribe(x => {            
              this.userGroup.patchValue(x.data.userDetails);
        });
  }

  get ug() { return this.userGroup.controls; }

  onUserGroupSubmit(){
    this.userSubmitted = true;    

    if(this.userGroup.invalid){
        return;
    }
    
    this.myProfileService.update(this.id, this.userGroup.value) 
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('Successfully updated my account details', { keepAfterRouteChange: true });
                //this.router.navigate(['../'], { relativeTo: this.route });
                this. loading = false;
            },
            error: error => {
                this.alertService.error('Unexpected error, your updates may have been partially updated or all changes lost. Retry or contact support for help.  Details: ' + error);
                //this.router.navigate(['../'], { relativeTo: this.route });
            }
        });
  }


  
  cancel(){
    this.router.navigate(['/']);
  }

  goToChangePassword(){
    this.router.navigate(['/users/change-password/' + this.id]);
  }
}
