<form [formGroup]="userGroup" (ngSubmit)="onUserGroupSubmit()">
    <h2>My Info</h2>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label>First Name</label>
            <input type="text" placeholder="First Name" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.firstName.errors }" />
            <div *ngIf="userSubmitted && ug.firstName.errors" class="invalid-feedback">
                <div *ngIf="ug.firstName.errors.required">First name is required</div>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.lastName.errors }" />
            <div *ngIf="userSubmitted && ug.lastName.errors" class="invalid-feedback">
                <div *ngIf="ug.lastName.errors.required">Last name is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Username</label>
            <input readonly type="text" placeholder="Username" formControlName="userName" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.userName.errors }" />
            <div *ngIf="userSubmitted && ug.userName.errors" class="invalid-feedback">
                <div *ngIf="ug.userName.errors.required">Username is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Email</label>
            <input readonly type="text" placeholder="your_email@website.com" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': userSubmitted && ug.email.errors }" />
            <div *ngIf="userSubmitted && ug.email.errors" class="invalid-feedback">
                <div *ngIf="ug.email.errors.required">Email is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label>Phone Number</label>
            <input type="text" placeholder="888-123-1234" formControlName="phoneNumber" class="form-control" />
        </div>
    </div>
    
    <button [disabled]="loading" class="btn btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
    </button>

    <a (click)="goToChangePassword()" class="btn btn-secondary password-btn">
        Change Password
    </a>
    <a (click)="cancel()"  class="btn btn-link">Cancel</a>
</form>