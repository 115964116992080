import { HttpClient, HttpEventType } from '@angular/common/http';
import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { _ } from 'ag-grid-community';
import { empty, Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { selectProductValidator } from 'src/app/_helpers/selectProductValidator.consts';
import { WeValidatorPatterns } from 'src/app/_helpers/weValidatorPatterns.consts';
import { OpenboxesProduct } from 'src/app/_services/integrationsTruth/entities/openboxes-product';
import { OpenBoxesUser } from 'src/app/_services/integrationsTruth/entities/openboxes-user';
import { OpenboxesProductsService } from 'src/app/_services/integrationsTruth/openboxes-products.service';
import { LuvSubnationalDivisionService } from 'src/app/_services/luv-subnational-division.service';
import { TokenStorageService } from 'src/app/_services/authentication-management/token-storage.service';
import { WeFile } from '../../file-upload/models/we-file';
import { OutboundOrderLineItem } from '../models/outbound-order-line-item';
import { OutboundOrdersService } from '../orders-outbound.service';
import { SavedRecipientsService } from '../saved-recipients/saved-recipients.service';
import { OrderShippedDetailsService } from './order-shipped-details.service';
import { OrderShippedDetails } from '../models/order-shipped-details';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';


@Component({
  selector: 'app-outbound-order',
  templateUrl: './outbound-order.component.html',
  styleUrls: ['./outbound-order.component.css']
})
export class OutboundOrderComponent implements OnInit {
  id: string;
  isCreateMode: boolean;
  isAdmin: boolean;
  editShippedInfo: boolean = false;
  orderHasShippingInfo: boolean = false;
  shippingLabelFileType: string = "OUTBOUND_ORDER_SHIPPING_LABEL";
  otherAttachmentsFileType: string = "OUTBOUND_ORDER_OTHER_ATTACHMENTS";
  shippingLabelBlobStorageIdsFromUpload : number[] = [];
  shippingLabelsFiles: WeFile[];
  otherAttachmentBlobStorageIdsFromUpload : number[] = [];
  otherAttachmentsFiles: WeFile[];
  clientGeneratesShippingLabel: boolean = false;
  orderDetailsGroup: FormGroup;
  lineDetailsGroup: FormGroup;
  ShippingAndDocsGroup: FormGroup;
  orderDetailsSubmitted = false;
  lineDetailsSubmitted = false;
  submitted = false;
  loading = false;
  allStates: any[];
  allSavedRecipientAddresses: any[];
  openboxesProducts: OpenboxesProduct[];
  openBoxUsers: OpenBoxesUser[];
  selectedSavedRecipient: any;
  sentTrackingNumber: string;
  sentService: string;
  shipmentDetails: any[] = [];
  @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;
  
  productIdCtrl = new FormControl();
  filteredProducts: Observable<OpenboxesProduct[]>[] = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private OrderService: OutboundOrdersService,
    private shippedDetailsService: OrderShippedDetailsService,
    private userRoleService: UserRoleService,
    private alertService: AlertService,
    private openboxesProductsService: OpenboxesProductsService,
    private tokenStorage: TokenStorageService,
    private subnationalDivisionService: LuvSubnationalDivisionService,
    private recipientService: SavedRecipientsService
    ) { 
        let isLoggedIn = this.tokenStorage.isLoggedIn();
        if(isLoggedIn){
          var user = this.tokenStorage.getUser();
          this.isAdmin = user.roles.includes('Admin');
        }


    }

  ngOnInit() : void {
    this.id = this.route.snapshot.params['id'];
    this.isCreateMode = !this.id;
    
    this.loadDropDataSources();    

        // password not required in edit mode
        const passwordValidators = [Validators.minLength(6)];
        if (this.isCreateMode) {
            passwordValidators.push(Validators.required);
        }

        this.orderDetailsGroup = this.formBuilder.group({
            name: ['', Validators.required],
            orderStatus: [''],
            dateRequested: ['', Validators.required],
            createdBy: ['', Validators.required],
            createdByFullName: [''],
            
            hasRecipient: ['checked', Validators.required],
            selectedSavedRecipient: [''], // for UI feature only
            recipient1: ['', Validators.required],
            recipient2: [''],
            address1: ['', Validators.required],
            address2: [''],
            address3: [''],
            city: ['', Validators.required],
            state: ['', [Validators.required, Validators.maxLength(3)]],
            zipCode: ['', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]],

            id: [''],
            openBoxesId: ['']
        }, {
            validator: null
        });

        this.lineDetailsGroup = this.formBuilder.group({            
            lineItems: this.formBuilder.array(
                [
                    this.formBuilder.group(
                    {
                        quantityOrdered: ['', Validators.required], 
                        product: [null, [Validators.required]]
                    }
                )])
        }, {
            validator: null
        });

        this.ShippingAndDocsGroup = this.formBuilder.group({
            clientGeneratesShippingLabel: ['', Validators.required],
            attachLabelNow: [''],
            weShippingMethod: [''],
            clientHasOtherDocumentsToUpload: [''],
            shippingLabelBlobIds: [''],            
            otherAttachmentsBlobIds: [''],

            notes: [''],
        }, {
            validator: null
        });

        if (!this.isCreateMode) {
            this.putFormInReadonly();

            this.OrderService.getById(this.id)
                .pipe(first())
                .subscribe(x => {
                        //console.log('x.data.clientGeneratesShippingLabel', x.data.clientGeneratesShippingLabel, 'data', x.data);
                        
                        // ENFORCE SECURITY ON THE PAGE
                        this.userRoleService.redirectIfNotAssociated3plData(x.data.client3plId);

                                                this.ShippingAndDocsGroup.get('clientGeneratesShippingLabel').setValue(x.data.clientGeneratesShippingLabel);
                        this.ShippingAndDocsGroup.get('attachLabelNow').setValue(x.data.attachLabelNow);
                        this.ShippingAndDocsGroup.get('notes').setValue(x.data.notes);
                        this.populateFormLineItems(x.data.lineItems);

                        this.sentService = x.data.sentService;
                        this.sentTrackingNumber = x.data.sentTrackingNumber;
                        this.shipmentDetails = x.data.shipments;

                        this.orderHasShippingInfo = this.isAdmin
                        ||(
                            x.data.orderStatus == 'ISSUED'
                            && this.sentTrackingNumber != null
                            && this.sentTrackingNumber != ''
                        );

                        
                        this.orderDetailsGroup.patchValue(x.data);
                        this.lineDetailsGroup.patchValue(x.data);
                        this.orderDetailsGroup.patchValue(x.data);
                        
                        this.ShippingAndDocsGroup.controls['weShippingMethod'].setValue(x.data.weShippingMethod);
                        
                        var dateControl = this.orderDetailsGroup.controls['dateRequested'] as FormControl;
                        var daysFromDateTime = x.data.dateRequested.substring(0, x.data.dateRequested.indexOf('T'));
                        dateControl.setValue(daysFromDateTime);

                        // mark all Steps completed in View mode
                        this.stepper.steps.forEach(s => {
                            s.completed = true;
                        })

                        this.shippingLabelsFiles = x.data.shippingLabelFiles;
                        this.otherAttachmentsFiles = x.data.otherAttachmentFiles;

                        if(x.data.hasRecipient){
                            this.orderDetailsGroup.get('hasRecipient').setValue('checked');
                        }

                        var hasOtherAttachments = 'yes';
                        if(this.otherAttachmentsFiles == undefined || this.otherAttachmentsFiles == null || this.otherAttachmentsFiles.length == 0){
                            hasOtherAttachments = 'no';
                        }
                        this.ShippingAndDocsGroup.controls['clientHasOtherDocumentsToUpload'].setValue(hasOtherAttachments);
                    }
                );
        }else{
            // set defaults if creating new order
            this.ShippingAndDocsGroup.controls['clientGeneratesShippingLabel'].setValue('no');
            this.ShippingAndDocsGroup.controls['attachLabelNow'].setValue('no');
            this.ShippingAndDocsGroup.controls['clientHasOtherDocumentsToUpload'].setValue('no');
            this.ShippingAndDocsGroup.controls['weShippingMethod'].setValue('leastCost');

            var currentDate = new Date().toLocaleDateString();
            var dateControl = this.orderDetailsGroup.controls['dateRequested'] as FormControl;
            dateControl.setValue(currentDate);

            var currentUserEmail = this.tokenStorage.getUser().email;
            var createdByControl = this.orderDetailsGroup.controls['createdBy'] as FormControl;
            createdByControl.setValue(currentUserEmail);
            var createdByFullControl = this.orderDetailsGroup.controls['createdByFullName'] as FormControl;
            createdByFullControl.setValue(currentUserEmail);
            this.orderDetailsGroup.controls['orderStatus'].setValue('NOT SAVED');
        }

        // add listener to set address fields when a recipient is selected
        if(this.isCreateMode){
            this.orderDetailsGroup.controls['selectedSavedRecipient']
                    .valueChanges
                    .subscribe(selectedRecipient => { 
                        if(selectedRecipient != null && selectedRecipient !== undefined){
                            this.setAddressToSavedRecipient(selectedRecipient);
                        }
                    });
        }
      }

    // convenience getter for easy access to form fields

    get odg() { return this.orderDetailsGroup.controls; }
    get ldg() { return this.lineDetailsGroup.controls; }
    get sdg() { return this.ShippingAndDocsGroup.controls; }

    private loadDropDataSources(){
        this.openboxesProductsService.getAllProducts()
            .pipe(first())
            .subscribe(x => {
                this.openboxesProducts = x.data;
            });

        this.subnationalDivisionService.getAll()
            .pipe(first())
            .subscribe(x => {
                this.allStates = x;
            })

        this.recipientService.getActive()
            .pipe(first())
            .subscribe(x => {
                this.allSavedRecipientAddresses = x;
            })
    }

    private putFormInReadonly(){
        this.orderDetailsGroup.disable();
        this.lineDetailsGroup.disable();
        this.ShippingAndDocsGroup.disable();
    }

    onHasRecipientChange($event){
        if($event.target.checked){
            // set all required fields to empty strings so user must enter fields

            var emptyString = '';
            var recipient1Control = this.orderDetailsGroup.controls['recipient1'] as FormControl;
            recipient1Control.setValue(emptyString);

            var address1Control = this.orderDetailsGroup.controls['address1'] as FormControl;
            address1Control.setValue(emptyString);

            var cityControl = this.orderDetailsGroup.controls['city'] as FormControl;
            cityControl.setValue(emptyString);

            var stateControl = this.orderDetailsGroup.controls['state'] as FormControl;
            stateControl.setValue(emptyString);


            var zipControl = this.orderDetailsGroup.controls['zipCode'] as FormControl;
            zipControl.setValue(emptyString);
            

            var recipient2Control = this.orderDetailsGroup.controls['recipient2'] as FormControl;
            recipient2Control.setValue(emptyString);    
    
            var address2Control = this.orderDetailsGroup.controls['address2'] as FormControl;
            address2Control.setValue(emptyString);
            
            var address3Control = this.orderDetailsGroup.controls['address3'] as FormControl;
            address3Control.setValue(emptyString);

            var savedRecipientSelectedControl = this.orderDetailsGroup.controls['selectedSavedRecipient'] as FormControl;
            savedRecipientSelectedControl.setValue(null);

        }
        else{
            // TODO: handle this better to make address optional
            // set all address required fields to whitespace strings to get past required fields

            var emptyString = '   ';
            var recipient1Control = this.orderDetailsGroup.controls['recipient1'] as FormControl;
            recipient1Control.setValue(emptyString);

            var address1Control = this.orderDetailsGroup.controls['address1'] as FormControl;
            address1Control.setValue(emptyString);

            var cityControl = this.orderDetailsGroup.controls['city'] as FormControl;
            cityControl.setValue(emptyString);

            var stateControl = this.orderDetailsGroup.controls['state'] as FormControl;
            stateControl.setValue(emptyString);


            var zipControl = this.orderDetailsGroup.controls['zipCode'] as FormControl;
            zipControl.setValue('12345'); // must pass regex test
        }
    }

    setAddressToSavedRecipient(recipientWithAddy){
        var recipient1Control = this.orderDetailsGroup.controls['recipient1'] as FormControl;
        recipient1Control.setValue(recipientWithAddy.recipient1);
        
        var recipient2Control = this.orderDetailsGroup.controls['recipient2'] as FormControl;
        recipient2Control.setValue(recipientWithAddy.recipient2);

        var address1Control = this.orderDetailsGroup.controls['address1'] as FormControl;
        address1Control.setValue(recipientWithAddy.address1);

        var address2Control = this.orderDetailsGroup.controls['address2'] as FormControl;
        address2Control.setValue(recipientWithAddy.address2);
        
        var address3Control = this.orderDetailsGroup.controls['address3'] as FormControl;
        address3Control.setValue(recipientWithAddy.address3);

        var cityControl = this.orderDetailsGroup.controls['city'] as FormControl;
        cityControl.setValue(recipientWithAddy.city);

        var stateObject = this.getStateByIsoCode(recipientWithAddy.state);
        var stateControl = this.orderDetailsGroup.controls['state'] as FormControl;
        stateControl.setValue(stateObject);

        var zipControl = this.orderDetailsGroup.controls['zipCode'] as FormControl;
        zipControl.setValue(recipientWithAddy.zipCode);

        // send user to the Next button to speed up their next natural action
        this.scroll('btnOrderDetailsNext');
    }

    private scroll(id){
        let el = document.getElementById(id);
        el.scrollIntoView();
    }

    onOrderDetailsSubmit(){
        this.orderDetailsSubmitted = true;

        if(this.orderDetailsGroup.invalid){
            //return; // TODO: right return?
        }
    }


    onLineDetailsSubmit(){
        this.lineDetailsSubmitted = true;

        if(this.lineDetailsGroup.invalid){
            return; // TODO: right return?
        }
    }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // TODO: fix this for stepper and not working before!! let me try to save
        // stop here if form is invalid
        if (this.ShippingAndDocsGroup.invalid) {
            return;
        }

        this.loading = true;
        if (this.isCreateMode) {
            this.createOrder();
        } else {
            this.updateOrder();
        }
    }

    productSearch(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.name.toLocaleLowerCase().indexOf(term) > -1 || 
        item.product_code.toLocaleLowerCase().indexOf(term) > -1 || 
        ('(' + item.product_code + ") " + item.name).toLocaleLowerCase().indexOf(term) > -1;
     }

    cancel(){
        if(this.isCreateMode){
            if(confirm('Are you sure you want to discard all your changes?')){
                this.router.navigate(['/orders-outbound']);
            }
        }else {
            this.router.navigate(['/orders-outbound']);
        }
    }

    saveShippedInfo(){
        let updateShippedRequest: OrderShippedDetails = {
            simpleWmsOrderId: this.id,
            trackingNumber: this.sentTrackingNumber,
            serviceShippedWith: this.sentService
        };
                
        this.shippedDetailsService.update(this.id, updateShippedRequest)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.editShippedInfo = false;
                    this.alertService.success('Successfully updated shipment info.');
                },
                error: error => {
                    this.alertService.error('Error updating shipment info. Try again or contact your admin');
                }
            });
    }

    onSelectionChange($event,lineIndex){
    }


    get lineItems() {
        return this.lineDetailsGroup.get('lineItems') as FormArray;
    }

    addLineItem() {
        this.lineItems.push(this.formBuilder.group({
            quantityOrdered: ['', Validators.required], 
            product: [null, [Validators.required]]
        }));
    }

    displayProductFn(product: OpenboxesProduct): string {
        return product && product.product_code ? "(" + product.product_code + ") " + product.name : '';
      }

    deleteLineItem(index) {
        this.lineItems.removeAt(index);
    }
    
    private populateFormLineItems(items: OutboundOrderLineItem[]) {
        // remove the empty declation row
        this.deleteLineItem(0);

        for(var i = 0; i < items.length; i++){
            var li = items[i];

            var productDropdownEntity = {

                 product_code: li.productCode,
                 id: li.productId,
                  name: li.productName,
                    price_per_unit: -1
            };

            this.lineItems.push(
                this.formBuilder.group({
                    
                    product: [productDropdownEntity, [Validators.required]],
                    quantityOrdered: [li.quantityOrdered, Validators.required]
                })
            )
        }
    }



    private createOrder() {
        //console.log('create called. form vals:', this.form.value);
        // default to an int for json conversion
        this.orderDetailsGroup.controls['id'].setValue(-1);

        this.ShippingAndDocsGroup.controls['shippingLabelBlobIds'].setValue(this.shippingLabelBlobStorageIdsFromUpload);
        this.ShippingAndDocsGroup.controls['otherAttachmentsBlobIds'].setValue(this.otherAttachmentBlobStorageIdsFromUpload);

        // set the current datetime to full DATETIME with UTC marker
        var currentDate = new Date().toISOString();
        var dateControl = this.orderDetailsGroup.controls['dateRequested'] as FormControl;
        dateControl.setValue(currentDate);

        if(this.orderDetailsGroup.get('hasRecipient').value == 'checked'){
            this.orderDetailsGroup.get('hasRecipient').setValue(true);
        }
        
        // if no recipient, change to C# friendly object
        if(this.orderDetailsGroup.get('state').value == '   '){
            this.orderDetailsGroup.get('state').setValue(null);
        }
        if(this.orderDetailsGroup.get('selectedSavedRecipient').value == '   ' 
            ||  this.orderDetailsGroup.get('selectedSavedRecipient').value == ''){
            this.orderDetailsGroup.get('selectedSavedRecipient').setValue(null);
        }

        var createJsonBody = {
            orderDetails: this.orderDetailsGroup.value,
            lineDetails: this.lineDetailsGroup.value,
            labelAndDocs: this.ShippingAndDocsGroup.value
        };
        
        this.OrderService.create(createJsonBody)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Order added', { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    // errored talking to openboxes, we know the order was made in Simple WMS first
                    // if(error.message?.indexOf('A connection attempt failed because the connected party did not properly respond after a period of time, or established connection')
                    //  > -1){
                    //     this.orderDetailsGroup.controls['orderStatus'].setValue('CREATED');
                    //  }
                    this.alertService.error('Unexpected error, your order may have been partially created or not created at all. Check all orders. Contact support for help.  Details: ' + error?.message);
                    // TODO: force user to scroll to top, so sees error message at top of screen!
                    this.loading = false;
                    //this.router.navigate(['../'], { relativeTo: this.route });
                }
            });
    }

    private updateOrder() {
        alert('TODO: update order');
    }

    private getStateByIsoCode(isoCode){
        return this.allStates.find(state => state.isoCode == isoCode);
    }

    goToShipmentDetails(){
        this.stepper.selectedIndex = (this.stepper.steps.length - 1);
    }

    public uploadShippingLabelFinished = (files) => {
        //var blobId = event.blobId;
        var fileIds: number[] = [];

        files.forEach(file => {
            fileIds.push(file.blobId);
        });
        
        this.shippingLabelBlobStorageIdsFromUpload = fileIds;

        // NOTE: we're leaving orphan records in dbo.BlobStorage.... :(
        //  going to be hard to sort out later... more or less... :-/
      }


      
    public uploadOtherAttachmentsFinished = (files) => {
        //var blobId = event.blobId;
        var fileIds: number[] = [];

        files.forEach(file => {
            fileIds.push(file.blobId);
        });
        
        this.otherAttachmentBlobStorageIdsFromUpload = fileIds;
      }
}
