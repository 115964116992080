import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { WarehouseEndOfDayService } from './warehouse-end-of-day.service';

@Component({
  selector: 'app-warehouse-manager',
  templateUrl: './warehouse-manager.component.html',
  styleUrls: ['./warehouse-manager.component.css']
})
export class WarehouseManagerComponent implements OnInit {
  endOfDayLoading: boolean = false;

  constructor(
    private userRoleService: UserRoleService,
    private endOfDayService: WarehouseEndOfDayService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.userRoleService.RedirectIfNotAdminUser();
  }

  runEndOfDayJob():void {
    if(confirm('Are you sure you want to run the end of day job? This may take several minutes and could impair daily in-progress jobs.')){
      this.endOfDayLoading = true;
      
      this.endOfDayService.runEndOfDayJob({})        
        .subscribe(
          result =>
          {
            this.endOfDayLoading = false;
            this.alertService.success('Completed End of Day Job');
          },
          error =>
          {
            this.alertService.error(
              'Unexpected error running Job. message:<br/><br/>' 
            + JSON.stringify(error));

            this.endOfDayLoading = false;
          }
        )
    }
  }

  runProductSync(): void{
    alert('THIS FEATURE HAS NOT IMPLEMENTED YET.')
  }
}
