<div class="form-row" 
  *ngIf="!readonly"
  style="margin-bottom:15px;">
    <div class="col-md-3">
      <input type="file" #file placeholder="Choose file" 
        (change)="uploadFile(file.files)"
        [multiple]="attachMultipleFiles"
        style="display:none;">
      <button type="button" class="btn btn-success" (click)="file.click()">
        {{ attachMultipleFiles ? 'Upload File(s)' : 'Upload File' }}
      </button>
    </div>
    <div class="col-md-4">
      <span class="upload" *ngIf="progress > 0">
        {{progress}}%
      </span>
      <span class="upload" *ngIf="message">
        {{message}}
      </span>
    </div>
  </div>
  <div class="form-row" style="margin-bottom:15px;">
    <div class="table">
      <div *ngFor="let file of files; let i = index" class="row">
        <div class="col-1 right-align-cell">
          <i class="fa fa-times red-text pointer" 
            title="Delete associated file"
            *ngIf="!readonly"
            (click)="onDeleteFile(file, i)"></i>
        </div>
        <div class="col-7">
          <a href="javascript:void()" role="button" (click)="downloadFile(file.blobId, file.fileName)">
            {{file.fileName}}
          </a>
        </div>
      </div>
    </div>
  </div>