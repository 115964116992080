import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService {

  constructor() { }

  
  public getWebServiceEndpoint(isVersionless: boolean = false) {
    // TODO: pull this from a config file that is updated by a build process
    //   cache it if/when we go to this! don't go get from server always
    
    ////// DEV //////
    //var baseUrl = 'https://localhost:44377/api/';
    
    ////// QA //////
    //var baseUrl = 'https://dev-wems-api.azurewebsites.net/api/';

    
    ////// PROD //////
    var baseUrl = 'https://simplewmsapi.azurewebsites.net/api/';
    
    if(isVersionless){
      return baseUrl;
    }
    else{
      return baseUrl + "v1/";
    }
  }
}
