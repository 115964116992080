import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GlobalConfigService } from './global-config.service';

type NewType = Observable<any>;

@Injectable({
  providedIn: 'root'
})
export class LuvSubnationalDivisionService {
  private API_URL: string;

  constructor(private http: HttpClient
    , private globalConfigService: GlobalConfigService) {    
      this.API_URL = this.globalConfigService.getWebServiceEndpoint() 
                      + 'SubnationalDivision/';
   }

   getAll(): Observable<any> {
    return this.http.get(this.API_URL
        , { responseType: 'json' });
  }
}
