<div class="table sticky-header">
  <div class="row">
    <div class="col-md-4">
      <h1>Outbound Orders</h1>
      <button mat-raised-button color="primary" (click)="goToAddOutboundOrder()">
        Add Order
      </button>
    </div>
    <div class="col-md-2">
      &nbsp;
    </div>
    <div class="col-md-2">      
      <popper-content #popper1Content>
        <ol class="order-status-list">
          <li><i>CREATED:</i>&nbsp;Order created from client portal.</li>        
          <li><i>REQUESTED:</i>&nbsp; Order received by warehouse for processing.</li>
          <li><i>PICKING:</i>&nbsp;Warehouse staff is picking order items.</li>
          <li><i>PICKED:</i>&nbsp;Order has been picked and waiting for quality check.</li>
          <li><i>CHECKING:</i>&nbsp;Order validated and is waiting for final shipping label</li>
          <li><i>ISSUED:</i>&nbsp;Shipping Label created for parcel and parcel has left the warehouse or will within 24 hours.</li>
        </ol>
      </popper-content>
      <div [popper]="popper1Content"
            [popperShowOnStart]="false"
            [popperTrigger]="'click'"
            [popperHideOnClickOutside]="true"
            [popperHideOnScroll]="true"
            [popperPlacement]="'bottom'">          
          <p class="pointer" style="font-weight: bold;">
            <i class="fa fa-info-circle"></i>&nbsp;&nbsp;What is the Order Status?
          </p>    
      </div>
    </div>
    <div class="col-md-4">
      &nbsp;
    </div>
  </div>
</div>
<div class="table">
  <div class="row filter-by-wrapper">
    <div class="col-md-3">
      <mat-form-field appearance="fill">
        <mat-label>Filter by keywords</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Enter 3 characters or more" 
            #input 
            autocomplete="off"
            class="filterInput">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill">
        <mat-label>Order Source</mat-label>
        <select 
          [(ngModel)]="filterOrderSource" 
          matNativeControl
          (change)="changeFilterSource($event)" >
          <option value="ANY">All</option>
          <option value="MANUAL">Manually Entered</option>
          <option value="INTEGRATION">Store Integration</option>
        </select>
      </mat-form-field>
    </div>    
    <div class="col-md-3">
      <mat-form-field appearance="fill">
        <mat-label>Order Status</mat-label>
        <mat-select [(value)]="filterStatuses" 
            multiple 
            (selectionChange)="changeFilterStatus($event)">
          <mat-option value="CREATED">CREATED</mat-option>
          <mat-option value="REQUESTED">REQUESTED</mat-option>
          <mat-option value="PICKING">PICKING</mat-option>
          <mat-option value="PICKED">PICKED</mat-option>
          <mat-option value="CHECKING">CHECKING</mat-option>
          <mat-option value="ISSUED">ISSUED / DISPATCHED</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill">
        <mat-label>Date Requested Between</mat-label>
        <mat-date-range-input [rangePicker]="dateRequestedPicker">
          <input matStartDate #dateRequestedStart 
              placeholder="After date" (dateChange)="changeFilterDate($event)" >
          <input matEndDate #dateRequestedEnd 
            placeholder="Before date" (dateChange)="changeFilterDate($event)" >
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="dateRequestedPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #dateRequestedPicker disabled="false"></mat-date-range-picker>
      </mat-form-field>
    </div>
    
  </div>
</div>


<table mat-table [dataSource]="dataSource" 
          class="mat-elevation-z8 table-wrapper"
          matSort matSortActive="dateRequested" matSortDirection="desc" matSortDisableClear>
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> 
          <!-- Simple WMS orders have readonly forms to see details -->
          <a *ngIf="element.orderSource == 'Manually Entered'" 
            title="{{element.genericId}}" 
            [routerLink]="[element.genericId]">
            #
            {{ (element.genericId.length > 5)
              ? '..' + (element.genericId | slice: (element.genericId.length-5))
              : (element.genericId)
             }}
          </a>

          <!-- ShipStation orders do note have details visible on a form -->
          <a *ngIf="element.orderSource != 'Manually Entered'" 
            title="{{element.genericId}}" 
            class="shipstation-id"
            [routerLink]="'ss/' + element.genericId" >
            #
            {{ (element.genericId.length > 5)
              ? '..' + (element.genericId | slice:(element.genericId.length-5))
              : (element.genericId)
             }}
            </a>
        </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="orderStatus">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Status</th>
      <td mat-cell *matCellDef="let element"> 
          <span class="order-status">{{element.orderStatus}} </span>
        </td>
    </ng-container>
  
    <!-- Order number Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Order # </th>
      <td mat-cell *matCellDef="let element" class="we-name-col"> 
        <!-- Simple WMS orders have links to readonly forms -->
        <a *ngIf="element.orderSource == 'Manually Entered'" 
          [routerLink]="[element.genericId]">
            {{element.name}} 
        </a>

        <!-- ShipStation orders link to SS order details page -->
        <a *ngIf="element.orderSource != 'Manually Entered'" class="shipstation-id" 
          [routerLink]="'ss/' + element.genericId">
          {{element.name}}
        </a>
      </td>
    </ng-container>

    <!-- Order source Column -->
    <ng-container matColumnDef="orderSource">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Order Source </th>
      <td mat-cell *matCellDef="let element"> 
        {{element.orderSource}}
      </td>
    </ng-container>
  
    <!-- DateRequested Column -->
    <ng-container matColumnDef="dateRequested">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Order Requested Date </th>
      <td mat-cell *matCellDef="let element"> {{element.dateRequested | date: 'mediumDate'}} </td>
    </ng-container>

    
    <!-- items orders Column -->
    <ng-container matColumnDef="itemsOrdered">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Total SKUs </th>
      <td mat-cell *matCellDef="let element"> {{element.numberOfLineItemsOrdered}} </td>
    </ng-container>

    <!-- number of items sold on orders Column -->
    <ng-container matColumnDef="totalUnits">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Total Units </th>
      <td mat-cell *matCellDef="let element"> {{element.totalUnits}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSize]="25"
                  class="pager-container"
                  showFirstLastButtons="true"
                   [pageSizeOptions]="[10, 25, 100, 250, 500]">
  </mat-paginator>