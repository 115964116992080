import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/lib/alerts/alert.service';
import { UserRoleService } from 'src/app/_services/authentication-management/user-role.service';
import { AssociatedClient3plsService } from '../../associated-client3pls/associated-client3pls.service';
import { RolesService } from '../roles.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  id: string;
  isCreateMode: boolean;
  userGroup: FormGroup;
  userSubmitted = false;
  loading = false;
  allRoles: string[] = [];
  all3plClients: any[] = [];
  rolesForThisUser: string[] = [];

  constructor(    
    private usersService: UsersService,
    private client3plService: AssociatedClient3plsService,
    private rolesService: RolesService,
    private userRoleService: UserRoleService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.userRoleService.RedirectIfNotAdminUser();
    this.id = this.route.snapshot.params['id'];
    this.isCreateMode = !this.id;

    this.userGroup = this.formBuilder.group({
      id: [''],
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: [''],
      fullName: [''], 
      associated3plConfigId: ['', Validators.required],      
      isActive: ['', Validators.required],
      roles: [''], // TODO: is handled in separate list and added to data on Submit
    }, {
        validator: null
    });

    this.loadPageData();
  }

  private loadPageData(): void{    
    
    if(!this.isCreateMode){
      this.usersService.getById(this.id)
        .pipe(first())
        .subscribe(x => {            
                this.userGroup.patchValue(x.data.userDetails);

              this.rolesForThisUser = x.data.userDetails.roles;
                
              var clientDropDownEntity = {
                name: x.data.userDetails.associated3plName,
                id: x.data.userDetails.associated3plConfigId,
                openBoxesId: '-1',  // TODO: doesn't matter to this user page...
                canAccessPortal: true,
              };

              this.userGroup.controls['associated3plConfigId'].setValue(clientDropDownEntity);              
        });
    }
      
    this.rolesService.getAllRoles()
      .pipe(first())
      .subscribe(x => {            
              this.allRoles = x
      });


      
    this.client3plService.getAllClient3pls()
      .subscribe(
        result =>
        {
          this.all3plClients = result.data.clients;
        },
        error =>
        {
          this.alertService.error(
            'Unexpected error getting 3PL CLients. message:<br/><br/>' 
          + JSON.stringify(error));
        }
      )
  }

  get ug() { return this.userGroup.controls; }

  onUserGroupSubmit(){
    this.userSubmitted = true;    

    if(this.userGroup.invalid){
        return;
    }

    if(this.rolesForThisUser.length < 1){
      alert('At least 1 Role is required.');
      return;
    }

    if(this.isCreateMode){
      this.createUser(this.userGroup.value);
    }
    else{
      this.updateUser(this.userGroup.value);
    }
  }  

  private createUser(newUser: any): void{
    this.loading = true;
     
    // set the form types to match the backend model instead of the UI model
    this.userGroup.controls['roles'].setValue(this.rolesForThisUser);
    var client3plSelectedObject = this.userGroup.controls['associated3plConfigId'].value;
    this.userGroup.controls['associated3plConfigId'].setValue(client3plSelectedObject.simpleWms3plConfigId);
    
    this.usersService.create(this.userGroup.value)    
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('User added', { keepAfterRouteChange: true });
                this.router.navigate(['../'], { relativeTo: this.route });
                this. loading = false;
            },
            error: error => {
                this.alertService.error('Unexpected error, your user may have been partially created or not created at all. Check all user. Contact support for help.  Details: ' + error);
                this. loading = false;
            }
        });
  }
  
  private updateUser(newUser: any): void{
    // set the form types to match the backend model instead of the UI model
    this.userGroup.controls['roles'].setValue(this.rolesForThisUser);
    var client3plSelectedObject = this.userGroup.controls['associated3plConfigId'].value;
    this.userGroup.controls['associated3plConfigId'].setValue(client3plSelectedObject.simpleWms3plConfigId);
    
    this.usersService.update(this.id, this.userGroup.value) 
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success('User Updated', { keepAfterRouteChange: true });
                this.router.navigate(['../'], { relativeTo: this.route });
                this. loading = false;
            },
            error: error => {
                this.alertService.error('Unexpected error, your user may have been partially updated or all changes lost. Retry or contact support for help.  Details: ' + error);
                this. loading = false;
                //this.router.navigate(['../'], { relativeTo: this.route });
            }
        });
  }

  
  clientSearch(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  roleChecked(roleClicked: string){
    const index = this.rolesForThisUser.indexOf(roleClicked);
    
    // ROLE FOUND, remove it from the user
    if(index > -1){
      this.rolesForThisUser.splice(index, 1);
    } 
    // ROLE NOT FOUND, add it to the user
    else{
      this.rolesForThisUser.push(roleClicked);
    } 
  }
  
  cancel(){
    if(this.isCreateMode){
        if(confirm('Are you sure you want to discard all your changes?')){
            this.router.navigate(['/users']);
        }
    }else {
        this.router.navigate(['/users']);
    }
  }

  goToChangePassword(){
    this.router.navigate(['/users/change-password/' + this.id]);
  }
}
