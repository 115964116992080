import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerWrapperComponent } from './spinner-wrapper/spinner-wrapper.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef = null;
  // array to keep track of all HTTP requests waiting for response from server
  // NOTE: interceptor must manage this list to make sure zeros out at end of page loads
  private openUrlsLoading: string[] = [];

  constructor(private overlay: Overlay) {}

  public show(urlRequesting: string, message = '') {
    // Returns an OverlayRef (which is a PortalHost)
      this.openUrlsLoading.push(urlRequesting);

    if (this.openUrlsLoading.length == 1){      

      if(!this.overlayRef) {
        this.overlayRef = this.overlay.create();
      }

      // Create ComponentPortal that can be attached to a PortalHost
      const spinnerOverlayPortal = new ComponentPortal(SpinnerWrapperComponent);
      const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }
  }

  public hide(urlRequesting: string) {
    // remove from list of open requests. has completed.
    var indexToRemove = this.openUrlsLoading.indexOf(urlRequesting);
    if(indexToRemove > -1){
      this.openUrlsLoading.splice(indexToRemove, 1);
    }else{
      console.log('WARNING: cannot find url in list of loading URLS.  User may not be able to load the page. URL:', urlRequesting);
    }


    // no items loading, hide indicator
    if (this.openUrlsLoading.length == 0 && !!this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}